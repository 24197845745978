import {Container, Typography} from "@mui/material";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="xl">
      {/* <Typography variant="body1" mt="1rem">
        Effective Date: 27/07/2023
      </Typography> */}
      <Typography variant="h4" mt="2rem">
        Privacy Policy
      </Typography>
      <Typography variant="body1" mt="2rem">
        Thank you for visiting plantspace.in. We are committed to protecting
        your privacy and ensuring the security of your personal information.
        This privacy policy outlines how we collect, use, disclose, and
        safeguard your information when you use our website. By accessing or
        using our website, you consent to the practices described in this
        policy.
      </Typography>
      <Typography variant="h5" mt="2rem">
        Information We Collect
      </Typography>
      <Typography variant="body1" mt="1rem">
        <ul>
          <li>
            <Typography
              variant="body1"
              fontWeight="500"
              component="span"
              mr="4px"
            >
              Personal Information:
            </Typography>
            When you visit our website, we may collect personal information that
            you voluntarily provide, such as your name, email address, postal
            address, phone number, and other contact details. You may provide
            this information when creating an account, making a purchase,
            subscribing to our newsletter, or contacting our customer support.
          </li>
          <li>
            <Typography
              variant="body1"
              fontWeight="500"
              component="span"
              mr="4px"
            >
              Website usage Information:
            </Typography>
            We may collect non-personal information about your interactions with
            our website, such as your IP address, browser type, device
            information, pages visited, and referring website. This information
            helps us improve our website's functionality, performance, and user
            experience.
          </li>
          <li>
            <Typography
              variant="body1"
              fontWeight="500"
              component="span"
              mr="4px"
            >
              Cookies and Similar Technologies:
            </Typography>
            We use cookies and similar technologies to enhance your browsing
            experience and gather information about how you use our website. You
            can manage your cookie preferences through your browser settings.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" mt="2rem">
        How We Use Your Information
      </Typography>
      <Typography variant="body1" mt="1rem">
        <ul>
          <li>
            <Typography
              variant="body1"
              fontWeight="500"
              component="span"
              mr="4px"
            >
              Provide Services:
            </Typography>
            We use your personal information to fulfill your orders, process
            payments, and deliver products or services you have requested. This
            includes sending order confirmations, shipping notifications, and
            providing customer support.
          </li>
          <li>
            <Typography
              variant="body1"
              fontWeight="500"
              component="span"
              mr="4px"
            >
              Communication:
            </Typography>
            We may use your contact information to send you updates about your
            orders, respond to your inquiries, and provide important
            notifications related to our website and services.
          </li>
          <li>
            <Typography
              variant="body1"
              fontWeight="500"
              component="span"
              mr="4px"
            >
              Marketing:
            </Typography>
            With your consent, we may use your email address to send you
            promotional materials, newsletters, and special offers. You can
            opt-out of marketing communications at any time by following the
            unsubscribe instructions provided in the emails.
          </li>
          <li>
            <Typography
              variant="body1"
              fontWeight="500"
              component="span"
              mr="4px"
            >
              Improve User Experience:
            </Typography>
            We analyze non-personal information to understand user behavior,
            identify trends, and improve our website's content, layout, and
            functionality.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" mt="2rem">
        Data Sharing and Disclosure
      </Typography>
      <Typography variant="body1" mt="1rem">
        <ul>
          <li>
            <Typography
              variant="body1"
              fontWeight="500"
              component="span"
              mr="4px"
            >
              Third-Party Service Providers:
            </Typography>
            We may share your personal information with trusted third-party
            service providers who assist us in operating our website, processing
            payments, and delivering products or services. These service
            providers are contractually obligated to protect your information
            and use it only for the purposes specified by us.
          </li>
          <li>
            <Typography
              variant="body1"
              fontWeight="500"
              component="span"
              mr="4px"
            >
              Legal Compliance:
            </Typography>
            We may disclose your personal information if required by law,
            government authorities, or to protect our rights, safety, or
            property.
          </li>
          <li>
            <Typography
              variant="body1"
              fontWeight="500"
              component="span"
              mr="4px"
            >
              Business Transfers:
            </Typography>
            In the event of a merger, acquisition, or sale of all or part of our
            business, your personal information may be transferred to the new
            entity or buyer as part of the transaction.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" mt="2rem">
        Data Security
      </Typography>
      <Typography variant="body1" mt="1rem">
        We take reasonable and appropriate measures to protect your personal
        information from unauthorized access, disclosure, alteration, and
        destruction. However, no data transmission over the internet or
        electronic storage method is entirely secure, and we cannot guarantee
        absolute security.
      </Typography>
      <Typography variant="h5" mt="2rem">
        Your Rights and Choices
      </Typography>
      <Typography variant="body1" mt="1rem">
        <ul>
          <li>
            <Typography
              variant="body1"
              fontWeight="500"
              component="span"
              mr="4px"
            >
              Access and Correction:
            </Typography>
            You have the right to access and update your personal information
            stored in your account. If you require assistance, please contact
            our customer support.
          </li>
          <li>
            <Typography
              variant="body1"
              fontWeight="500"
              component="span"
              mr="4px"
            >
              Opt-Out:
            </Typography>
            You can opt-out of receiving marketing communications by following
            the unsubscribe instructions provided in the emails or by contacting
            us directly.
          </li>
          <li>
            <Typography
              variant="body1"
              fontWeight="500"
              component="span"
              mr="4px"
            >
              Do Not Track:
            </Typography>
            Our website does not respond to "Do Not Track" signals from your
            browser.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" mt="2rem">
        Children's Privacy
      </Typography>
      <Typography variant="body1" mt="1rem">
        Our website is not intended for use by individuals under the age of 13.
        We do not knowingly collect personal information from children under 13.
        If you believe we have inadvertently collected such information, please
        contact us to have it removed.
      </Typography>
      <Typography variant="h5" mt="2rem">
        Changes to Privacy Policy
      </Typography>
      <Typography variant="body1" mt="1rem">
        We reserve the right to modify or update this privacy policy at any time
        without prior notice. Any changes will be effective immediately upon
        posting on our website. It is your responsibility to review the privacy
        policy periodically for any updates.
      </Typography>
      <Typography variant="h5" mt="2rem">
        Contact Information
      </Typography>
      <Typography variant="body1" mt="1rem" component="span" mr="4px">
        If you have any questions or concerns regarding this privacy policy or
        our data practices, please contact us at
      </Typography>
      <Typography variant="body1" fontWeight="500" component="span" mr="4px">
        support@plantspace.in
      </Typography>
      <Typography variant="body1" component="span" mr="4px">
        or call us at
      </Typography>
      <Typography variant="body1" fontWeight="500" component="span" mr="4px">
        +91-8527884232
      </Typography>
      <Typography variant="body1" mt="1rem" mb="2rem">
        By using our website, you acknowledge that you have read, understood,
        and agreed to this privacy policy.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
