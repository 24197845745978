import {
  doc,
  serverTimestamp,
  setDoc,
  collection,
  getDocs,
  query,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import {db} from "../../firebase";

export default class ProductsAPI {
  // Add/Update Product
  async setProduct(data, updateTime = true) {
    console.log(
      "API CALL =========> set Product--add form data:",
      data.payload,
      " to id:",
      data.id
    );
    const payloadData = updateTime ? {createdAt: serverTimestamp()} : {};
    try {
      await setDoc(
        doc(db, "products", data.id),
        {...data.payload, ...payloadData},
        {
          merge: true,
        }
      );
      return {success: true};
    } catch (ex) {
      console.log(ex);
      return {success: false, message: ex};
    }
  }

  // Add/Update Category
  async setCategory(data) {
    console.log(
      "API CALL =========> add category form data:",
      data.payload,
      " to id:",
      data.id
    );
    try {
      await setDoc(
        doc(db, "categories", data.id),
        {...data.payload, createdAt: serverTimestamp()},
        {
          merge: true,
        }
      );
      return {success: true};
    } catch (ex) {
      console.log(ex);
      return {success: false, message: ex};
    }
  }

  // Fetch all products
  async getProducts() {
    try {
      console.log("API CALL =========> getProducts:");
      const q = query(collection(db, "products"), orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);
      let proArr = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        proArr.push({id: doc.id, ...doc.data()});
      });
      return {success: true, data: proArr};
    } catch (ex) {
      console.log(ex);
      return {success: false, message: ex};
    }
  }
  // Fetch all categories
  async getCategories() {
    try {
      console.log("API CALL =========> getCategories:");

      //////////// Subscribe for changes in collection

      // const q = query(collection(db, "categories"));
      // const unsubscribe = onSnapshot(q, (querySnapshot) => {
      //   let categories = [];
      //   querySnapshot.forEach((doc) => {
      //     categories.push({ id: doc.id, ...doc.data() });
      //   });
      //   console.log("Current categories: ", categories);
      //   return { success: true, data: categories };
      // });

      //////////////////////////////////////////////////

      ///////////// Get data once
      const q = query(collection(db, "categories"), orderBy("name"));

      const querySnapshot = await getDocs(q);
      let catArr = [];
      querySnapshot.forEach((doc) => {
        // console.log("API:", doc);
        // doc.data() is never undefined for query doc snapshots
        catArr.push({id: doc.id, ...doc.data()});
      });
      return {success: true, data: catArr};
    } catch (ex) {
      console.log(ex);
      return {success: false, message: ex};
    }
  }

  async deleteProduct(prodId) {
    try {
      await deleteDoc(doc(db, "products", prodId));
      return {success: true};
    } catch (ex) {
      console.log(ex);
      return {success: false, message: ex};
    }
  }
  async deleteCategory(catId) {
    try {
      await deleteDoc(doc(db, "categories", catId));
      return {success: true};
    } catch (ex) {
      console.log(ex);
      return {success: false, message: ex};
    }
  }
}
