import React, {useState, useContext, useEffect, useRef} from "react";
import {
  Typography,
  Grid,
  Box,
  IconButton,
  Tooltip,
  Paper,
  Modal,
  Skeleton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import CustomButton from "../../common/Button/CustomButton";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ImageCarousel from "../../common/ImageCarousel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CartItemsContext from "../../../context/cartItems/CartItemsContext";
import DeleteIcon from "@mui/icons-material/Delete";
import StarRating from "./../Products/StarRating";
import {useNavigate} from "react-router-dom";
import PlaceOrder from "../PlaceOrder/PlaceOrder";
import Loader from "../../loader/Loader";
import CustomisedSnackbar from "../../common/Snackbar/CustomisedSnackbar";
import AlertDialogSlide from "../../common/Dialog/AlertDialogSlide";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {md: "75%", sm: "85%", xs: "90%"},
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 10,
  p: 2, //padding
  paddingBottom: 1,
  minHeight: "90%",
  maxHeight: "100%",
  overflowY: "overlay",
  overflowX: "hidden",
};
const Root = styled("div")({
  flexGrow: 1,
  padding: {xs: "0", sm: "1rem"},
  marginTop: {xs: "0", sm: "0.5rem"},
});

const Cart = () => {
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const snackbarMessage = useRef("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const cartItemsContext = useContext(CartItemsContext);
  const products = cartItemsContext.state;
  const [deleteItemId, setDeleteItemId] = useState("");
  const itemToRemoveRef = useRef({id: "", title: ""});
  const [loading, setLoading] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const idToTitle = (id) => {
    const prod = products.filter((pro) => pro.id === id);
    console.log("prod:", prod);
    if (prod && prod.length === 1) {
      return prod[0].title;
    }
    return "TitleNotFound";
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleAgreeDialog = () => {
    setDeleteItemId(itemToRemoveRef.current.id);
    setOpenDialog(false);
  };
  const handleMinusIconClick = (item_id) => {
    setLoading(true);
    const item_title = idToTitle(item_id);
    snackbarMessage.current = "Removed 1 unit of " + item_title;
    setTimeout(() => {
      const productIndex = cartItemsContext.state.findIndex(
        (obj) => obj.id === item_id
      );
      const products_ = [...cartItemsContext.state];
      products_[productIndex] = {
        ...products_[productIndex],
        quantity: products_[productIndex].quantity - 1,
      };
      cartItemsContext.setState(products_);
    }, 500);
  };
  const handlePlusIconClick = (item_id) => {
    setLoading(true);
    const item_title = idToTitle(item_id);
    snackbarMessage.current = "Added 1 unit of " + item_title;

    setTimeout(() => {
      const productIndex = cartItemsContext.state.findIndex(
        (obj) => obj.id === item_id
      );
      const products_ = [...cartItemsContext.state];
      products_[productIndex] = {
        ...products_[productIndex],
        quantity: products_[productIndex].quantity + 1,
      };
      cartItemsContext.setState(products_);
    }, 500);
  };
  const removeProduct = (item_id) => {
    itemToRemoveRef.current = {id: item_id, title: idToTitle(item_id)};
    setOpenDialog(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowSkeleton(false);
    }, 1000);
  }, []);

  useEffect(() => {
    console.log("deleteItemId:", deleteItemId);
    if (deleteItemId !== "") {
      const item_title = idToTitle(deleteItemId);
      snackbarMessage.current = item_title + " removed from cart";
      const productIndex = cartItemsContext.state.findIndex(
        (obj) => obj.id === deleteItemId
      );
      const products_ = [...cartItemsContext.state];
      products_.splice(productIndex, 1);
      cartItemsContext.setState(products_);
      setTimeout(() => {
        if (snackbarMessage.current !== "") setOpenSnackbar(true);
        setDeleteItemId("");
      }, 500);
    }
  }, [deleteItemId]);

  useEffect(() => {
    console.log("cartItemsContext.state:", cartItemsContext.state);
    if (loading) {
      setLoading(false);
      setOpenSnackbar(true);
    }
  }, [cartItemsContext.state]);
  return (
    <>
      <Root>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={2}
        >
          {/* For "Your Cart with Total amount and Checkout Button" */}
          <Grid item container xs={12} sm={12} md={10} lg={10}>
            <Grid
              item
              container
              justifyContent="flex-start"
              alignItems="center"
              xs={6}
            >
              <Grid
                item
                container
                spacing={0}
                justifyContent="flex-start"
                alignItems="flex-end"
                xs={12}
                md={6}
              >
                <Grid item xs={12} sm="auto" sx={{marginRight: "0.5rem"}}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{fontWeight: 500}}
                  >
                    Your Cart
                  </Typography>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Typography
                    variant="body2"
                    className="cart-subtotal-text small"
                  >
                    ({products.reduce((a, v) => a + v.quantity, 0)} items)
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
                xs={12}
                md={6}
                display={{xs: "flex", sm: "flex", md: "none", lg: "none"}}
              >
                <Grid item>
                  <Typography variant="body2" className="cart-subtotal-text">
                    Total amount
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h5"
                    className="cart-subtotal-price small"
                  >
                    &#8377;
                    {products.reduce((a, v) => a + v.listPrice * v.quantity, 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              xs={6}
              spacing={2}
            >
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-end"
                xs={12}
                md={6}
                display={{xs: "none", sm: "none", md: "flex", lg: "flex"}}
              >
                <Grid item>
                  <Typography variant="body2" className="cart-subtotal-text">
                    Total amount
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" className="cart-subtotal-price">
                    &#8377;{" "}
                    {products.reduce(
                      (a, v) => (a = a + v.listPrice * v.quantity),
                      0
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems="center"
                xs="auto"
              >
                <CustomButton
                  variant="contained"
                  startIcon={<ShoppingCartCheckoutIcon />}
                  size="small"
                  onClick={handleOpen}
                >
                  Place Order
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
          {/* For Item Rows */}
          <Grid item container xs={12} sm={12} md={10} lg={10}>
            {products.map((product, index) => (
              <Paper
                key={index}
                sx={{
                  width: "100%",
                  position: "relative",
                  p: 1,
                  margin: "1rem 0",
                  flexGrow: 1,
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                }}
                className={`cart-item-paper ${
                  deleteItemId === product.id ? "fadeOut" : ""
                }`}
              >
                <IconButton
                  color="error"
                  className="icon-float delete-icon"
                  onClick={() => {
                    removeProduct(product.id);
                  }}
                  sx={{right: "-16px !important"}}
                >
                  <DeleteIcon sx={{width: "1.25em", height: "1.25em"}} />
                </IconButton>
                <Grid container spacing={2}>
                  {/* For Item image, [ Quantity for mobile only] */}
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={4}
                    sm={3}
                    md={2}
                    lg={2}
                  >
                    {/* <Box
                    className="cart-item-img"
                    sx={{
                      backgroundImage: `url(${product.image})`,
                    }}
                  ></Box> */}

                    {/* For Item Image */}
                    <Grid
                      item
                      xs={12}
                      sx={{cursor: "pointer"}}
                      onClick={() =>
                        navigate(
                          `/collections/${product.parentCategory.id}/${product.id}`
                        )
                      }
                    >
                      {!showSkeleton && (
                        <Box sx={{marginBottom: 0}}>
                          <ImageCarousel
                            ImagesArr={product.images.map((a) => a.url)}
                            autoPlayOnHover={true}
                          />
                        </Box>
                      )}
                      {showSkeleton && (
                        <>
                          <Skeleton
                            variant="rounded"
                            height={mobileScreen ? 75 : 140}
                            width="100%"
                          />
                        </>
                      )}
                    </Grid>
                    {/* Quantity for mobile only */}
                    <Grid
                      display={{
                        xs: "flex",
                        sm: "none",
                        md: "none",
                        lg: "none",
                      }}
                      item
                      container
                      justifyContent="center"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        spacing={1}
                      >
                        <Grid
                          item
                          container
                          justifyContent="center"
                          alignItems="center"
                          xs={5}
                        >
                          <Tooltip title="Remove 1 item" placement="top">
                            <span>
                              <IconButton
                                aria-label="remove item"
                                disabled={product.quantity === 1}
                                onClick={() => handleMinusIconClick(product.id)}
                                sx={{textAlign: "center"}}
                              >
                                <RemoveCircleOutlineIcon
                                  sx={{fontSize: "1.5rem"}}
                                />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          container
                          justifyContent="center"
                          alignItems="center"
                          xs={2}
                        >
                          <Typography variant="string" component="p">
                            {product.quantity}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={5}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Tooltip title="Add 1 item" placement="top">
                            <span>
                              <IconButton
                                aria-label="add item"
                                disabled={product.quantity > 4}
                                onClick={() => handlePlusIconClick(product.id)}
                              >
                                <AddCircleOutlineIcon
                                  sx={{fontSize: "1.5rem"}}
                                />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* For Item Title, PricePerUnit [Subtotal for mobile only]*/}
                  <Grid
                    item
                    xs={8}
                    sm={5}
                    md={5}
                    lg={5}
                    container
                    alignItems="flex-start"
                    justifyContent="space-between"
                    spacing={1}
                  >
                    {/* For Title, Rating and PricePerUnit */}
                    <Grid
                      item
                      sx={{cursor: "pointer"}}
                      onClick={() =>
                        navigate(
                          `/collections/${product.parentCategory.id}/${product.id}`
                        )
                      }
                    >
                      <Typography
                        variant="h6"
                        className="cart-item-title bold"
                        sx={{lineHeight: 1.3}}
                      >
                        {product.title}
                      </Typography>
                      {product.ratings && product.ratings.length > 0 && (
                        <StarRating
                          rating={
                            Math.round(
                              product.ratings.reduce((a, b) => a + b, 0) /
                                product.ratings.length /
                                0.5
                            ) * 0.5
                          }
                          showTotalNumber={product.ratings.length}
                          showLabel={false}
                        />
                      )}
                      {product.ratings === undefined ||
                        (product.ratings.length === 0 && (
                          <StarRating
                            rating={0}
                            showTotalNumber={false}
                            showLabel={false}
                          />
                        ))}
                      <Typography
                        className="discount-percent-detail"
                        variant=""
                      >
                        -
                        {Math.ceil(
                          ((product.maxPrice - product.listPrice) /
                            product.maxPrice) *
                            100
                        )}
                        %
                      </Typography>
                      <Typography
                        variant="body"
                        color="primary"
                        className="list-price-detail"
                      >
                        &#8377;{product.listPrice}
                      </Typography>
                      <Typography
                        variant=""
                        display={{
                          xs: "none",
                          sm: "inline",
                          md: "inline",
                          lg: "inline",
                        }}
                      >
                        M.R.P.
                      </Typography>

                      <Typography className="max-price" variant="">
                        &#8377;{product.maxPrice}
                      </Typography>
                    </Grid>

                    {/*  Subtotal amount for mobile only */}
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={2}
                      display={{
                        xs: "flex",
                        sm: "none",
                        md: "none",
                        lg: "none",
                      }}
                    >
                      <Grid item>
                        <Typography
                          variant="body2"
                          className="cart-subtotal-text"
                        >
                          subtotal
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" className="item-total-amount">
                          &#8377; {product.listPrice * product.quantity}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* For Item Units and subtotal [For big screens only] */}
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    sm={4}
                    md={5}
                    lg={5}
                    display={{
                      xs: "none",
                      sm: "flex",
                      md: "flex",
                      lg: "flex",
                    }}
                  >
                    <Grid
                      item
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      sm={6}
                      md={6}
                      spacing={1}
                    >
                      <Grid item display={{sm: "none", md: "none", lg: "flex"}}>
                        <Typography variant="body2">Quantity</Typography>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Remove 1 item" placement="top">
                          <span>
                            <IconButton
                              disabled={product.quantity === 1}
                              onClick={() => handleMinusIconClick(product.id)}
                              sx={{textAlign: "center"}}
                            >
                              <RemoveCircleOutlineIcon
                                sx={{fontSize: "1.5rem"}}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Typography variant="string" component="span">
                          {product.quantity}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Add 1 item" placement="top">
                          <span>
                            <IconButton
                              disabled={product.quantity > 4}
                              onClick={() => handlePlusIconClick(product.id)}
                            >
                              <AddCircleOutlineIcon sx={{fontSize: "1.5rem"}} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      sm={6}
                      md={6}
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography
                          variant="body2"
                          className="cart-subtotal-text"
                        >
                          Subtotal
                        </Typography>
                        <Typography variant="h6" className="item-total-amount">
                          &#8377; {product.listPrice * product.quantity}
                        </Typography>
                      </Grid>
                      {/* <Grid item>
                      <Tooltip title="Remove item" placement="top">
                        <IconButton aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Grid>

          {/* For Checkout button */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CustomButton
              startIcon={<ShoppingCartCheckoutIcon />}
              variant="contained"
              fullWidth={true}
              onClick={handleOpen}
            >
              Place Order
            </CustomButton>
          </Grid>
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Place Order
            </Typography>
            <Box id="modal-modal-description" sx={{mt: 1, height: "92%"}}>
              <PlaceOrder handleClose={handleClose} />
            </Box>
          </Box>
        </Modal>
        <CustomisedSnackbar
          message={snackbarMessage.current}
          severity="success"
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
        />
        <AlertDialogSlide
          handleOnAgree={handleAgreeDialog}
          handleOnClose={handleCloseDialog}
          isOpen={openDialog}
          title="Remove Item"
          description={
            "Sure to remove " + itemToRemoveRef.current.title + " from cart ?"
          }
        />
      </Root>

      {loading && <Loader />}
    </>
  );
};

export default Cart;
