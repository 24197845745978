import React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
const ScrollHandler = (props) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.window ? window() : undefined,
  });

  return React.cloneElement(props.children, {
    style: {
      backgroundColor:
        trigger || props.pathname !== "/" || props.isMobile
          ? process.env.REACT_APP_BG_COLOR
          : "transparent",
      color: "white",
      transition: trigger || props.pathname !== "/" ? "0.3s" : "0.5s",
      boxShadow: "none",
      padding: "10px 0px",
      marginTop:
        trigger || props.pathname !== "/"
          ? "0"
          : props.isMobile
          ? "0px"
          : "34px",
    },
  });
};

const ScrollToColor = (props) => {
  return <ScrollHandler {...props}>{props.children}</ScrollHandler>;
};

export default ScrollToColor;
