/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import AuthAPI from "../../../api/firebase/AuthAPI";
import { Avatar, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CustomDataGrid from "../../../components/common/DataGrid/CustomDataGrid";
import DataGridActions from "../../../components/admin/datagrid-actions/DataGridActions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AllUsers = () => {
  const [usersArr, setUsersArr] = useState([]);
  const [rowId, setRowId] = useState(null);
  const authAPI = new AuthAPI();

  // Fetch all users
  useEffect(() => {
    const resUsers = authAPI.getAllUsers();
    resUsers.then((resData) => {
      // console.log("received:", resData);
      if (!resData) {
        return;
      }
      setUsersArr(resData.data);
      console.log("Done fetching all users: ", usersArr);
    });
  }, []);

  // For snackbar
  const [snackbarState, setSnackbarState] = useState(false);
  const [message, setMessage] = useState({ text: "", type: "" });
  const showSnackbar = () => {
    setSnackbarState(true);
  };
  const hideSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState(false);
  };

  //// Columns for Users DataGrid
  const columns = useMemo(
    () => [
      {
        field: "photoURL",
        headerName: "Image",
        width: 80,
        renderCell: (params) => {
          if (
            params.row.photoURL !== undefined &&
            params.row.photoURL !== null
          ) {
            return <Avatar src={params.row.photoURL} />;
          }
          return <Avatar src="/static/images/avatar/1.jpg" />;
        },
        sortable: false,
        filterable: false,
      },
      { field: "displayName", headerName: "Name", width: 150 },
      { field: "email", headerName: "Email", width: 250 },
      {
        field: "role",
        headerName: "Role",
        width: 150,
        type: "singleSelect",
        valueOptions: ["User", "Admin"],
        editable: true,
        renderCell: (params) => {
          return (
            <div className="rowitem">
              {params.row.role.includes("Admin") ? "Admin" : "User"}
            </div>
          );
        },
      },

      {
        field: "createdAt",
        headerName: "Created at",
        width: 250,
        renderCell: (params) => {
          const t = new Date(
            params.row.createdAt.seconds * 1000
          ).toLocaleString("en-GB", {
            timeZone: "IST",
          });
          return <div className="rowitem">{t}</div>;
        },
      },
      { field: "id", hide: true },
      {
        field: "actions",
        headerName: "Action",
        type: "actions",
        renderCell: (params) => (
          <DataGridActions {...{ params, rowId, setRowId, onSave: true }} />
        ),
      },
    ],
    [rowId]
  );

  /// Add Category to firebase
  const handleSetUser = (dataToUpdate) => {
    // API to add product data to firestore
    const res = authAPI.setUserInTable(dataToUpdate);
    res
      .then((resData) => {
        if (resData.success) {
          setMessage({
            text: "User updated successfully !",
            type: "success",
          });
          showSnackbar();
        } else {
          setMessage({
            text: "Some error occured. Please try again !!",
            type: "error",
          });
          console.log(resData.message);
        }
      })
      .catch((ex) => {
        setMessage({
          text: "Some error occured. Please try again !!",
          type: "error",
        });
        console.log(ex);
      });
  };

  return (
    <Grid2 container spacing={2}>
      <Snackbar
        open={snackbarState}
        autoHideDuration={3000}
        onClose={hideSnackbar}
      >
        <Alert
          onClose={hideSnackbar}
          severity={message.type}
          sx={{ width: "100%" }}
        >
          {message.text}
        </Alert>
      </Snackbar>

      <Grid2 xs={12} mt={2}>
        <CustomDataGrid
          rows={usersArr}
          columns={columns}
          styles={{ height: "540px" }}
          pageSizes={[10, 25, 50]}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Grid2>
    </Grid2>
  );
};

export default AllUsers;
