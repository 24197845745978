import {Box, Divider, Grid, LinearProgress, Typography} from "@mui/material";
import React from "react";
import ReviewCard from "./ReviewCard";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
const CustomerReviews = ({productReviewsData, allReviews}) => {
  let proReviewsArr = null;
  if (allReviews && allReviews.length > 0) {
    proReviewsArr = allReviews.filter((item) => {
      return productReviewsData.reviews.includes(item.id);
    });
    console.log("reviewsArr: ", proReviewsArr);
  }

  const initCounts = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0};
  const [ratingPercent, setRatingPercent] = React.useState(initCounts);
  const [reviewToShow, setReviewToShow] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  //console.log("ratingPercent:", ratingPercent);

  const countRatings = () => {
    const tempCounts = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0};
    for (const num of productReviewsData.ratings) {
      switch (num) {
        case 1:
          tempCounts[1]++;
          break;
        case 2:
          tempCounts[2]++;
          break;
        case 3:
          tempCounts[3]++;
          break;
        case 4:
          tempCounts[4]++;
          break;
        case 5:
          tempCounts[5]++;
          break;
      }
    }
    //console.log("tempCounts:", tempCounts);
    const ratings = {};
    [1, 2, 3, 4, 5].forEach((element) => {
      ratings[element] = Math.round(
        (tempCounts[element] / productReviewsData.ratings.length) * 100
      );
    });
    setRatingPercent(ratings);
  };
  React.useEffect(() => {
    console.log("reviewToShow:", reviewToShow);
  }, [reviewToShow]);

  React.useEffect(() => {
    if (productReviewsData.ratings && productReviewsData.ratings.length) {
      countRatings();
      if (proReviewsArr) {
        setReviewToShow({...proReviewsArr[0]});
      }
    }
  }, [productReviewsData]);
  const handleReviewChange = (event, value) => {
    console.log(
      "proReviews:",
      proReviewsArr,
      " value:",
      value,
      " show reviewData:",
      proReviewsArr[value - 1]
    );
    if (proReviewsArr[value - 1]) {
      setReviewToShow({...proReviewsArr[value - 1]});
      setCurrentPage(value);
    }
  };
  return (
    <Box sx={{flexGrow: 1}}>
      <Grid container spacing={2}>
        <Grid container item xs={12} md={6} spacing={2}>
          <Grid item xs={12} sm={10} md={8}>
            <Typography variant="h6">Customer Ratings</Typography>
          </Grid>
          {[5, 4, 3, 2, 1].map((item) => {
            return (
              <Grid item xs={12} sm={10} md={8} key={item}>
                <Box sx={{display: "flex", alignItems: "center"}}>
                  <Box sx={{minWidth: "10%", mr: 1}}>
                    <Typography variant="body2" sx={{fontWeight: "500"}}>
                      {item} Star
                    </Typography>
                  </Box>
                  <Box sx={{width: "60%", mr: 1}}>
                    <LinearProgress
                      variant="determinate"
                      value={ratingPercent[item]}
                      sx={{height: "15px", borderRadius: "3px"}}
                    />
                  </Box>
                  <Box sx={{minWidth: "10%"}}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{fontWeight: "500"}}
                    >{`${Math.round(ratingPercent[item])}%`}</Typography>
                  </Box>
                </Box>
                {item === 1 && (
                  <Divider sx={{marginTop: "1rem", marginBottom: "1rem"}} />
                )}
              </Grid>
            );
          })}
        </Grid>
        <Grid container item xs={12} md={6} className="reviews-container">
          <Grid item xs={12} sm={10} md={8}>
            <Typography variant="h6">Reviews</Typography>
            <Typography variant="body2">
              {productReviewsData.reviews === undefined ||
              productReviewsData.reviews.length === 0
                ? "(No Reviews yet)"
                : ""}
            </Typography>
            {proReviewsArr !== null && reviewToShow !== null && (
              <Stack spacing={2}>
                <ReviewCard review={reviewToShow} />
                <Pagination
                  count={proReviewsArr.length}
                  page={currentPage}
                  onChange={handleReviewChange}
                  color="primary"
                  sx={{width: "max-content"}}
                />
              </Stack>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerReviews;
