import { Check, Delete, Edit, Save } from "@mui/icons-material";
import { Box, CircularProgress, Fab, IconButton, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { green } from "@mui/material/colors";
import AuthAPI from "../../../api/firebase/AuthAPI";

const DataGridActions = ({
  params,
  rowId = null,
  setRowId,
  onEdit = null,
  onDelete = null,
  onVisibilityChange = null,
  onSave = null,
}) => {
  let toolbar_title = "";
  let icon = null;
  let item = null;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const authAPI = new AuthAPI();
  if (onVisibilityChange !== null) {
    // Check If Product Or Category
    item = params.row.visible !== undefined ? "Product" : "Category";

    const proVisibility =
      item === "Product" ? params.row.visible : params.row.showInNavbar;
    const place = item === "Product" ? "website" : "navbar";
    toolbar_title = proVisibility
      ? "Hide " + item + " from " + place
      : "Show " + item + " on " + place;
    icon = proVisibility ? (
      <VisibilityOffIcon color="visibilityIcon" />
    ) : (
      <VisibilityIcon color="visibilityIcon" />
    );
  }
  const handleSave = () => {
    setLoading(true);
    const userRole = params.row.role === "Admin" ? ["User", "Admin"] : ["User"];
    const userData = {
      id: params.id,
      payload: {
        role: userRole,
      },
    };
    authAPI.setUserInTable(userData).then((data) => {
      setLoading(false);
      setSuccess(true);
      setRowId(null);
    });
  };
  useEffect(() => {
    if (rowId === params.id && success) setSuccess(false);
  }, [rowId]);

  return (
    <Box>
      {onSave !== null && (
        <Box sx={{ position: "relative" }}>
          {success ? (
            <Fab
              color="primary"
              sx={{
                width: 40,
                height: 40,
                bgcolor: green[500],
                "&:hover": { bgcolor: green[700] },
              }}
            >
              <Check />
            </Fab>
          ) : (
            <Fab
              sx={{
                width: 40,
                height: 40,
              }}
              disabled={params.id !== rowId || loading}
              onClick={handleSave}
            >
              <Save />
            </Fab>
          )}
          {loading && (
            <CircularProgress
              size={52}
              sx={{
                color: green[500],
                position: "absolute",
                top: -6,
                left: -6,
                zIndex: 1,
              }}
              thickness={2}
            />
          )}
        </Box>
      )}

      {onVisibilityChange !== null && (
        <Tooltip title={toolbar_title}>
          <IconButton
            onClick={() => {
              console.log("params:", params.row);
              const payload =
                item === "Product"
                  ? {
                      visible: !params.row.visible,
                    }
                  : {
                      showInNavbar: !params.row.showInNavbar,
                    };
              onVisibilityChange("UPDATE", params.row.id, payload);
            }}
          >
            {icon}
          </IconButton>
        </Tooltip>
      )}
      {onEdit !== null && (
        <Tooltip
          title={onVisibilityChange === null ? "Edit Category" : "Edit Product"}
        >
          <IconButton
            onClick={() => {
              onEdit(params.row);
            }}
          >
            <Edit color="primary" />
          </IconButton>
        </Tooltip>
      )}
      {onDelete !== null && (
        <Tooltip
          title={
            onVisibilityChange === null ? "Delete Category" : "Delete Product"
          }
        >
          <IconButton
            onClick={() => {
              onDelete(params.row.id);
            }}
          >
            <Delete color="danger" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default DataGridActions;
