import {
  Avatar,
  Box,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Rating from "@mui/material/Rating";
import CustomButton from "../../common/Button/CustomButton";
import CreateIcon from "@mui/icons-material/Create";
import ReviewsAPI from "../../../api/firebase/ReviewsAPI";
import UserProfileContext from "../../../context/userProfile/UserProfileContext";
import ProductsAPI from "../../../api/firebase/ProductsAPI";
import Loader from "../../loader/Loader";
import UserDataAPI from "../../../api/firebase/UserDataAPI";
import LoginPage from "../../../pages/authentication/login";
import AllProductContext from "../../../context/allProducts/AllProductsContext";
import AllReviewsContext from "../../../context/allReviews/AllReviewsContext";

const AddReview = ({
  product,
  reviewData = {
    headline: "",
    detail: "",
    rating: 0,
  },
  handleClose,
}) => {
  const [ratingValue, setRatingValue] = React.useState(0);
  const [headlineText, setHeadlineText] = React.useState("");
  const [detailText, setDetailText] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [userProfile, setUserProfile] = React.useState({});
  const userProfileContext = React.useContext(UserProfileContext);
  const allProductsContext = React.useContext(AllProductContext);
  const allReviewsContext = React.useContext(AllReviewsContext);

  const reviewsAPI = new ReviewsAPI();
  const productsAPI = new ProductsAPI();
  const userDataApi = new UserDataAPI();

  React.useEffect(() => {
    if (reviewData !== null && reviewData.rating !== 0) {
      setHeadlineText(reviewData.headline);
      setDetailText(reviewData.detail);
      setRatingValue(reviewData.rating);
    }
  }, []);

  const generateRandomString = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  React.useEffect(() => {
    const user = userProfileContext.state;
    console.log("user:", user);

    if (user !== null) {
      setUserProfile({...user});
    } else {
      setUserProfile(null);
    }
  }, [userProfileContext.state]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    const data = new FormData(event.currentTarget);
    if (data.get("headline") === "" || data.get("headline").length < 2) {
      setError(true);
    } else {
      setError(false);
      if (ratingValue > 0) {
        const reviewId =
          reviewData !== null && reviewData.rating !== 0
            ? reviewData.id
            : generateRandomString(12);
        // Submit review
        const dataToSend = {
          id: reviewId,
          payload: {
            id: reviewId,
            product_id: product.id,
            user_id: userProfile.id,
            display_name: userProfile.displayName,
            email: userProfile.email,
            photoURL: userProfile.photoURL || null,
            headline: data.get("headline"),
            detail: data.get("description"),
            rating: ratingValue,
          },
        };
        console.log("data to send:", dataToSend);
        setLoading(true);
        reviewsAPI
          .setReview(dataToSend)
          .then((res) => {
            if (res) {
              console.log("setReview RES:", res);
              const oldReviewsForProd = product.reviews || [];
              const oldRatingsForProd = product.ratings || [];
              let payloadData = {};
              if (reviewData !== null && reviewData.rating !== 0) {
                const indexOfOldRating = oldRatingsForProd.indexOf(
                  reviewData.rating
                );
                oldRatingsForProd[indexOfOldRating] = ratingValue;
                payloadData = {
                  ratings: [...oldRatingsForProd],
                };
              } else {
                payloadData = {
                  reviews: [...oldReviewsForProd, reviewId],
                  ratings: [...oldRatingsForProd, ratingValue],
                };
              }

              const dataToProducts = {
                id: product.id,
                payload: payloadData,
              };
              // Add review to products table
              productsAPI
                .setProduct(dataToProducts, false)
                .then((res) => {
                  if (res) {
                    //Review Added Successfully, Update product context
                    const allProArr = allProductsContext.state;
                    const indexOfProduct = allProArr.findIndex(
                      (a) => a.id === product.id
                    );
                    allProArr[indexOfProduct] = {
                      ...product,
                      ...payloadData,
                    };
                    allProductsContext.setState([...allProArr]);
                    const oldReviewsByUser = userProfile.reviews || [];
                    const currentTime = new Date();
                    if (reviewData !== null && reviewData.rating !== 0) {
                      // Update all reviews context
                      const allReviewsArr = allReviewsContext.state;
                      const indexOfReview = allReviewsArr.findIndex(
                        (a) => a.id === reviewData.id
                      );

                      allReviewsArr[indexOfReview] = {
                        ...dataToSend.payload,
                        createdAt: currentTime,
                      };
                      allReviewsContext.setState([...allReviewsArr]);
                      setTimeout(() => {
                        setLoading(false);
                        handleClose();
                      }, 500);
                    } else {
                      const dataToUser = {
                        id: userProfile.id,
                        payload: {
                          reviews: [...oldReviewsByUser, reviewId],
                        },
                      };
                      userDataApi
                        .addUserData(dataToUser, false)
                        .then((res) => {
                          if (res) {
                            //Review Added Successfully, Update user profile context
                            userProfileContext.setState({
                              ...userProfileContext.state,
                              reviews: [...oldReviewsByUser, reviewId],
                            });

                            // Update all reviews context
                            allReviewsContext.setState([
                              ...allReviewsContext.state,
                              {...dataToSend.payload, createdAt: currentTime},
                            ]);
                            setTimeout(() => {
                              setLoading(false);
                              handleClose();
                            }, 500);
                          } else {
                            alert("Error Adding Review to users table");
                          }
                        })
                        .catch((ex) => {
                          setLoading(false);
                          console.error("EX:", ex);
                        });
                    }
                  } else {
                    handleClose();
                    alert("Error Adding Review to produts table");
                  }
                })
                .catch((ex) => {
                  setLoading(false);
                  console.error("EX:", ex);
                });
            } else {
              setLoading(false);
              alert("Error Adding Review to reviews table");
            }
          })
          .catch((ex) => {
            setLoading(false);
            console.error("EX:", ex);
          });
      }
    }
    // console.log({
    //   headline: data.get("headline"),
    //   description: data.get("description"),
    //   rating: ratingValue,
    // });
  };
  const handleHeadlineChange = (event) => {
    console.log(event.target.value);
    setHeadlineText(event.target.value);
    if (event.target.value.length > 2) {
      setError(false);
    }
  };
  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />

      {loading && <Loader fullHeight={false} />}
      {userProfile === null && <LoginPage redirect={false} />}
      {userProfile !== null && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
            <CreateIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {reviewData !== null && reviewData.rating !== 0
              ? "Edit review"
              : "Add review"}
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" component="span" sx={{mr: "1rem"}}>
                    Overall Rating
                  </Typography>
                  <Rating
                    name="overall-rating"
                    size="large"
                    value={ratingValue}
                    onChange={(event, newValue) => {
                      setRatingValue(newValue);
                    }}
                  />
                </Box>
              </Grid>
              {(ratingValue === 0 || ratingValue === null) && submitted && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    pt: "0 !important",
                  }}
                >
                  <Typography
                    variant="p"
                    component="p"
                    sx={{
                      textAlign: "center",
                      mt: "0",
                      pt: "0",
                    }}
                    color="red"
                  >
                    (Please select rating)
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  value={headlineText}
                  type="text"
                  id="headline"
                  label="Headline"
                  name="headline"
                  placeholder="Most important thing to know"
                  onChange={handleHeadlineChange}
                />
              </Grid>
              {error && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    pt: "0 !important",
                  }}
                >
                  <Typography
                    variant="p"
                    component="p"
                    sx={{
                      textAlign: "center",
                      mt: "0",
                      pt: "0",
                    }}
                    color="red"
                  >
                    (Please enter headline)
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  defaultValue={detailText}
                  name="description"
                  label="Detailed Review"
                  type="text"
                  id="description"
                  placeholder="What did you like or dislike?"
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
            >
              Submit Review
            </CustomButton>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default AddReview;
