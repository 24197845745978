import React, {useContext, useEffect, useState} from "react";
import LikedItemsContext from "../../../context/likedItems/LikedItemsContext";
import ProductsContainer from "../../../components/app/Products/ProductsContainer";
import {Divider, Grid, Typography, styled} from "@mui/material";
import CustomButton from "../../../components/common/Button/CustomButton";
import cartEmptyGIF from "./../../../assets/gifs/cart-empty.gif";
import {useNavigate} from "react-router-dom";
import AllProductContext from "../../../context/allProducts/AllProductsContext";
import AllCategoriesContext from "../../../context/allCategories/AllCategoriesContext";
import RecentlyViewedContext from "../../../context/recentlyViewed/RecentlyViewedContext";
const Root = styled("div")({
  flexGrow: 1,
  padding: "1rem",
});

const LikedItemsPage = () => {
  const likedItemsContext = useContext(LikedItemsContext);
  const allProductContext = useContext(AllProductContext);
  const allCategoriesContext = useContext(AllCategoriesContext);
  const recentlyViewedContext = useContext(RecentlyViewedContext);
  const recentlyViewedItemsArray = recentlyViewedContext.state;
  const [allCategoriesArr, setAllCategoriesArr] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [likedItemsArray, setLikedItemsArray] = useState([]);
  useEffect(() => {
    const tempLikedItems = likedItemsContext.state;
    setLikedItemsArray(tempLikedItems);
  }, [likedItemsContext.state]);
  // Fetch all categories
  useEffect(() => {
    setAllCategoriesArr(allCategoriesContext.state);
  }, [allCategoriesContext.state]);
  useEffect(() => {
    // console.log("allCategoriesArr:", allCategoriesArr);
    const parentCats = allCategoriesArr.filter((item) => {
      return item.parentCategory.id === "None";
    });
    setParentCategories(parentCats);
    // console.log("parentCats:", parentCats);
  }, [allCategoriesArr]);
  const navigate = useNavigate();
  return (
    <Root>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {likedItemsArray.length > 0 && (
            <ProductsContainer
              heading="My Wishlist"
              showInRow={false}
              addToCartEnable={true}
              showCarousel={false}
              productsArray={likedItemsArray}
              fadeOutOnRemovingLike={true}
            />
          )}
          {likedItemsArray.length == 0 && (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={10} sm={6} md={3}>
                <img width="100%" src={cartEmptyGIF} alt="loading..." />
                <Typography
                  sx={{textAlign: "center", marginTop: "1rem"}}
                  variant="h6"
                >
                  You don't have any item in your Wishlist
                </Typography>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={10} sm={6} md={3}>
                <CustomButton
                  fullWidth={true}
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Browse Products
                </CustomButton>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* Recently Viewed Items */}
      </Grid>
      {recentlyViewedItemsArray.length > 0 && (
        <Grid
          container
          item
          spacing={2}
          sx={{
            marginTop: "1rem",
          }}
        >
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ProductsContainer
              heading="Recently Viewed"
              showInRow={true}
              addToCartEnable={true}
              showCarousel={false}
              productsArray={recentlyViewedItemsArray}
            />
          </Grid>
        </Grid>
      )}
    </Root>
  );
};

export default LikedItemsPage;
