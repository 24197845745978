import {Box, Container, Divider, Grid, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";

const AppFooter = () => {
  return (
    <Container
      maxWidth={false}
      sx={{mt: "3rem", pb: "2rem", background: "#007d821a"}}
      disableGutters
    >
      <Divider />
      <Grid
        container
        spacing={2}
        sx={{mt: "1rem", p: "1rem"}}
        justifyContent="center"
      >
        <Grid item xs={12} sm={3}>
          <Typography variant="h6" className="footer-item-heading">
            Policies
          </Typography>
          <Typography variant="body2">
            <Link to="/privacy-policy" className="footer-item link">
              Privacy Policy
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link to="/refund-policy" className="footer-item link">
              Refund Policy
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link to="/shipping-policy" className="footer-item link">
              Shipping Policy
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link to="/terms-and-conditions" className="footer-item link">
              Terms and Conditions
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="h6" className="footer-item-heading">
            About
          </Typography>
          <Typography variant="body2">
            <Link to="/about-plant-space" className="footer-item link">
              Know Plant Space
            </Link>
          </Typography>

          <Typography variant="body2">
            <Link to="/contactUs" className="footer-item link">
              Contact
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="h6" className="footer-item-heading">
            Contact
          </Typography>
          <Typography variant="body2" className="footer-item">
            Plant Space,
            <br />
            Ahmad Bagh, Court Road
            <br /> Saharanpur, U.P. - INDIA
          </Typography>
          <Typography variant="body2" className="footer-item" mt="0.5rem">
            Email : info@plantspace.in
          </Typography>
          <Typography variant="body2" className="footer-item">
            Call or Whatsapp : +91-8527884232
          </Typography>
        </Grid>

        <Grid item xs={12} justifyContent="center" alignItems="center">
          <Typography variant="body2" sx={{textAlign: "center"}}>
            Website developed and maintained by
            <br />
            <a href="https://softaim.in">SoftAim Technologies Pvt. Ltd.</a>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AppFooter;
