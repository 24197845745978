import {Container, Typography} from "@mui/material";
import React from "react";

const ContactUsPage = () => {
  return (
    <Container maxWidth="xl">
      <Typography variant="h4" mt="2rem">
        Contact us
      </Typography>
      <Typography variant="p" mt="0.5rem">
        Plant Space,
        <br />
        Ahmad Bagh, Court Road
        <br /> Saharanpur, U.P. - INDIA
      </Typography>
      <Typography variant="p" mt="0.5rem">
        Email : info@plantspace.in
      </Typography>
      <Typography variant="p">Call or Whatsapp : +91-8527884232</Typography>
    </Container>
  );
};

export default ContactUsPage;
