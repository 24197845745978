import React, {useEffect} from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import {Grid, Toolbar, useMediaQuery, useTheme} from "@mui/material";

import "./App.css";

// Pages
import Homepage, {DataLoader} from "./pages/app/home";
import CollectionsPage from "./pages/app/collections";
import LoginPage from "./pages/authentication/login";
import SignUpPage from "./pages/authentication/signup";
import ProductsListAdmin from "./pages/admin/products";
import Orders from "./pages/admin/orders";
import Categories from "./pages/admin/categories";
import AllUsers from "./pages/admin/users";
import CartPage from "./pages/app/cart";
import AdminDashboard from "./pages/admin/dashboard";
import LikedItemsPage from "./pages/app/likedItems";
import UserProfilePage from "./pages/user/UserProfilePage";
import UserAddressesPage from "./pages/user/UserAddressesPage";
import UserOrdersPage from "./pages/user/UserOrdersPage";
import OrderDetailPage from "./pages/user/OrderDetailPage";
import PageNotFound from "./pages/errors/PageNotFound";
import ContactUsPage from "./pages/app/contactUs";
import TermsAndConditions from "./pages/app/termsAndConditions";
import PrivacyPolicy from "./pages/app/privacy";

// Layouts
import {default as AdminDrawer} from "./components/layouts/admin/drawer";
import DrawerAppBar from "./components/layouts/app/appbar/DrawerAppBar";

// Contexts
import CartItemsState from "./context/cartItems/CartItemsState";
import UserProfileState from "./context/userProfile/UserProfileState";
import ColorModeState from "./context/colorMode/ColorModeState";
import AddProductState from "./context/addProduct/AddProductState";
import AllProductState from "./context/allProducts/AllProductsState";
import AllCategoriesState from "./context/allCategories/AllCategoriesState";
import AddCategoryState from "./context/addCategory/AddCategoryState";
import RecentlyViewedState from "./context/recentlyViewed/RecentlyViewedState";
import LikedItemsState from "./context/likedItems/LikedItemsState";

//Components
import PrivateRoute from "./components/private-route/PrivateRoute";
import NetworkStatus from "./components/network-status/NetworkStatus";
import RefundPolicy from "./pages/app/refundPolicy";
import AppFooter from "./components/layouts/app/footer/AppFooter";
import Footer from "./components/layouts/app/footer/Footer";
import AboutPage from "./pages/app/about";
import ShippingPolicy from "./pages/app/shippingPolicy";
import FixedBottomNavigation from "./components/layouts/app/appbar/FixedBottomNavigation";
import AddReview from "./components/app/Reviews/AddReview";
import AllReviewsState from "./context/allReviews/AllReviewsState";
export default function App() {
  const loader = document.getElementById("loader");
  useEffect(() => {
    if (loader) {
      setTimeout(() => {
        loader.style.display = "none";
      }, 2000);
    }
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Root />} errorElement={<PageNotFound />}>
          <Route index element={<Homepage />} loader={DataLoader} />/
          <Route
            path="collections"
            element={<CollectionsPage />}
            loader={DataLoader}
          >
            <Route path=":categoryId" element={<CollectionsPage />}>
              <Route path=":itemId" element={<CollectionsPage />} />
            </Route>
          </Route>
          <Route path="/contactUs" element={<ContactUsPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/about-plant-space" element={<AboutPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/myWishlist" element={<LikedItemsPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route
            path="/userProfile"
            element={<PrivateRoute Component={UserProfilePage} />}
          />
          <Route
            path="/userAddresses"
            element={
              <PrivateRoute accessTo="User" Component={UserAddressesPage} />
            }
          />
          <Route
            path="userOrders"
            element={
              <PrivateRoute accessTo="User" Component={UserOrdersPage} />
            }
          />
          <Route
            path="orderDetail"
            element={
              <PrivateRoute accessTo="User" Component={OrderDetailPage} />
            }
          />
        </Route>
        <Route
          path="/admin"
          element={
            <PrivateRoute accessTo="Admin" Component={Root} layout="ADMIN" />
          }
        >
          <Route path="/admin/dashboard" element={<AdminDashboard />}></Route>
          <Route path="/admin/products" element={<ProductsListAdmin />} />
          <Route path="/admin/categories" element={<Categories />} />
          <Route path="/admin/orders" element={<Orders />} />
          <Route path="/admin/users" element={<AllUsers />} />
        </Route>
      </>
    )
  );
  return (
    <>
      <ColorModeState>
        <AllReviewsState>
          <AllProductState>
            <AllCategoriesState>
              <AddProductState>
                <AddCategoryState>
                  <UserProfileState>
                    <CartItemsState>
                      <LikedItemsState>
                        <RecentlyViewedState>
                          <RouterProvider router={router} />
                        </RecentlyViewedState>
                      </LikedItemsState>
                    </CartItemsState>
                  </UserProfileState>
                </AddCategoryState>
              </AddProductState>
            </AllCategoriesState>
          </AllProductState>
        </AllReviewsState>
      </ColorModeState>
    </>
  );
}

const Root = ({layout = ""}) => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  if (layout === "ADMIN") {
    return (
      <Grid container>
        <Grid item xs={12}>
          <NetworkStatus />
          <AdminDrawer />
        </Grid>
      </Grid>
    );
  } else if (layout === "") {
    return (
      <>
        <NetworkStatus />

        <DrawerAppBar />
        {/* <Toolbar variant={mobileScreen ? "dense" : "regular"} />
        {mobileScreen && <Toolbar variant="dense" />} */}
        {/* <AddReview /> */}
        <Outlet />

        <AppFooter />
        {mobileScreen && <FixedBottomNavigation />}

        <ScrollRestoration />
      </>
    );
  }
};
