import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Rating,
  Typography,
} from "@mui/material";
import {red} from "@mui/material/colors";
import React from "react";

const ReviewCard = ({review}) => {
  let dateTimeStr = "";

  try {
    const dateCreated = review.createdAt.toDate().toDateString();
    const timeCreated = review.createdAt.toDate().toLocaleTimeString();
    dateTimeStr = dateCreated + " " + timeCreated;
  } catch {
    dateTimeStr =
      review.createdAt.toDateString() +
      " " +
      review.createdAt.toLocaleTimeString();
  }

  console.log("dateCreated:", dateTimeStr);
  return (
    <Card sx={{minHeight: "250px"}}>
      <CardHeader
        avatar={
          <Avatar
            alt={review.display_name}
            src={
              review.photoURL && review.photoURL !== null
                ? review.photoURL
                : "/static/images/avatar/1.jpg"
            }
            sx={{width: 48, height: 48, margin: "auto"}}
          />
        }
        sx={{textAlign: "left", fontWeight: 500}}
        title={review.display_name}
        subheader={dateTimeStr}
      />
      {/* <CardMedia
        component="img"
        height="194"
        image="/static/images/cards/paella.jpg"
        alt="Paella dish"
      /> */}
      <CardContent sx={{textAlign: "center", mt: "1rem"}}>
        <Typography variant="h5">
          <Rating name="overall-rating" size="large" value={review.rating} />
        </Typography>
        <Typography variant="h5" sx={{fontWeight: "500"}}>
          {review.headline}
        </Typography>
        <Typography variant="h6" sx={{fontWeight: "300"}}>
          {review.detail}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ReviewCard;
