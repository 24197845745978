import {
  Chip,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, {useState, useEffect} from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
function valuetext(value) {
  return `&#8377;${value}`;
}
const ProductFilters = ({
  initPriceRange = {min: 0, max: 0},
  onSelectFilters = null,
}) => {
  const minDistance = 10;
  const priceStep = 10;

  const [priceRange, setPriceRange] = useState([
    initPriceRange.min,
    initPriceRange.max,
  ]);
  const [ratingValue, setRatingValue] = useState(0);
  const [selFilters, setSelFilters] = useState([]);
  const minLabel = "₹" + initPriceRange.min;
  const maxLabel = "₹" + initPriceRange.max;
  const points = [
    {
      value: initPriceRange.min,
      label: minLabel,
    },
    {value: initPriceRange.max, label: maxLabel},
  ];
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  console.log("mobileScreen:", mobileScreen);
  const handleRatingChange = (event) => {
    setRatingValue(event.target.value);
  };
  const handlePriceRangeChange = (event, newValue, activeThumb) => {
    if (
      !Array.isArray(newValue) ||
      (newValue[0] === priceRange[0] && newValue[1] === priceRange[1])
    ) {
      return;
    }
    if (activeThumb === 0) {
      setPriceRange([
        Math.min(newValue[0], priceRange[1] - minDistance),
        priceRange[1],
      ]);
    } else {
      setPriceRange([
        priceRange[0],
        Math.max(newValue[1], priceRange[0] + minDistance),
      ]);
    }
  };
  useEffect(() => {
    setPriceRange([initPriceRange.min, initPriceRange.max]);
    setRatingValue(0);
    setSelFilters([]);
  }, [initPriceRange]);

  useEffect(() => {
    console.log("priceRange:", priceRange);
    let idx = -1;
    let temp_filters = [...selFilters];
    temp_filters.map((item, index) => {
      if (item.type === "PRICE") {
        idx = index;
      }
    });
    if (idx !== -1) {
      temp_filters.splice(idx, 1);
    }
    const filter = {
      type: "PRICE",
      value: {gt: priceRange[0], lt: priceRange[1]},
    };
    temp_filters.push(filter);

    if (
      priceRange[0] === initPriceRange.min &&
      priceRange[1] === initPriceRange.max
    ) {
      temp_filters.splice(idx, 1);
    }

    console.log("temp_filters:", temp_filters);
    setSelFilters([...temp_filters]);
  }, [priceRange]);

  useEffect(() => {
    if (ratingValue === 0) {
      return;
    }
    let idx = -1;
    let temp_filters = [...selFilters];
    temp_filters.map((item, index) => {
      if (item.type === "RATING") {
        idx = index;
      }
    });
    if (idx !== -1) {
      temp_filters.splice(idx, 1);
    }
    const filter = {
      type: "RATING",
      value: ratingValue,
    };
    temp_filters.push(filter);
    console.log("temp_filters:", temp_filters);
    setSelFilters([...temp_filters]);
  }, [ratingValue]);

  useEffect(() => {
    console.log("selFilters changed:", selFilters);
    onSelectFilters(selFilters);
  }, [selFilters]);
  const removeFilter = (filter, index) => {
    let temp_filters = [...selFilters];
    temp_filters.splice(index, 1);
    setSelFilters([...temp_filters]);
    if (filter.type === "PRICE") {
      setPriceRange([initPriceRange.min, initPriceRange.max]);
    }
    if (filter.type === "RATING") {
      setRatingValue(0);
    }
  };
  if (!mobileScreen) {
    return (
      <div
        className="full-height"
        style={{
          marginTop: "1rem",
          padding: "1rem",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h6">Filters</Typography>
            {selFilters.length > 0 &&
              selFilters.map((filter, index) => {
                if (filter.type === "PRICE") {
                  const lbl = "₹" + filter.value.gt + "-₹" + filter.value.lt;
                  return (
                    <Chip
                      key={index}
                      label={lbl}
                      variant="outlined"
                      onDelete={() => removeFilter(filter, index)}
                      style={{margin: "0.25rem"}}
                    />
                  );
                }
                if (filter.type === "RATING") {
                  const lbl = filter.value + "★ & above";
                  return (
                    <Chip
                      key={index}
                      label={lbl}
                      variant="outlined"
                      onDelete={() => removeFilter(filter, index)}
                      style={{margin: "0.25rem"}}
                    />
                  );
                }
              })}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              padding: 0,
              marginTop: selFilters.length > 0 ? "1rem" : "0",
            }}
          >
            <Divider light />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              padding: "1rem",
            }}
          >
            <FormLabel>Price Range</FormLabel>
            {/* <Typography variant="p">Price Range</Typography> */}
            <Box>
              <Slider
                size="small"
                getAriaLabel={() => "Price range"}
                value={priceRange}
                onChange={handlePriceRangeChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                step={priceStep}
                min={initPriceRange.min}
                max={initPriceRange.max}
                marks={points}
                disableSwap
              />
            </Box>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              className="price-box"
            >
              <Box>
                <Typography variant="body2">Min</Typography>
                <Typography variant="body2">&#8377;{priceRange[0]}</Typography>
              </Box>
              <Box>
                <Typography variant="body2">Max</Typography>
                <Typography variant="body2">&#8377;{priceRange[1]}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} style={{padding: 0}}>
            <Divider light />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              padding: "1rem",
            }}
          >
            {/* <Typography variant="p">Customer Ratings</Typography> */}
            <FormLabel id="demo-controlled-radio-buttons-group">
              Customer Ratings
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={ratingValue}
              onChange={handleRatingChange}
            >
              <FormControlLabel
                value={4}
                control={<Radio />}
                label="4★ & above"
              />
              <FormControlLabel
                value={3}
                control={<Radio />}
                label="3★ & above"
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </div>
    );
  }
  // if (mobileScreen) {
  //   return (
  //     <Container>
  //       <Chip
  //         label="Sort By"
  //         variant="outlined"
  //         style={{margin: "0.25rem"}}
  //         deleteIcon={<KeyboardArrowDownIcon />}
  //       />
  //     </Container>
  //   );
  // }
};

export default ProductFilters;
