import React, {useContext, useState, useEffect} from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CustomButton from "../../common/Button/CustomButton";
import AuthAPI from "../../../api/firebase/AuthAPI";
import {Link} from "react-router-dom";
import Alert from "@mui/material/Alert";
import {
  Collapse,
  IconButton,
  Modal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import SignUpPage from "../../../pages/authentication/signup";
import UserProfileContext from "../../../context/userProfile/UserProfileContext";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link to="/" className="link bold copyright-appname">
        {process.env.REACT_APP_NAME}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const authAPI = new AuthAPI();
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs: "100%", sm: "60%"},
  height: {xs: "100%", sm: "inherit"},
  bgcolor: "background.paper",
  boxShadow: 24,
  p: {
    xs: 2,
    sm: 4,
    md: 6,
  },
  borderRadius: "5px",
};
export default function LoginForm({redirect = true}) {
  // Redirect is used for login in Placeorder component
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const userProfileContext = useContext(UserProfileContext);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    console.log(
      "LOGIN FORM =====> userProfileContext.state:",
      userProfileContext.state
    );
    if (userProfileContext.state !== null) {
      setLoading(false);
    }
  }, [userProfileContext.state]);

  const handleSubmit = (event) => {
    console.log("login");
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    console.log({
      email,
      password,
    });

    authAPI.signInWithEmailPassword(email, password).then((res) => {
      console.log("LOGIN FORM: signInWithEmailPassword:", res);
      //setLoading(false);
      if (!res.status) {
        let errMsg = res.error.message;
        if (
          res.error.code === "auth/wrong-password" ||
          res.error.code === "auth/user-not-found" ||
          res.error.code === "auth/invalid-login-credentials"
        ) {
          errMsg = "Invalid Login Credentials";
        } else if (res.error.code === "auth/invalid-email") {
          errMsg = "Invalid Email Id";
        }
        setError(errMsg);
      }
    });
  };
  const handleGoogleSignIn = () => {
    setLoading(true);
    authAPI
      .signInWithGoogle()
      .then((data) => {
        console.log("LOGIN FORM===> google signed in user :", data.user);
        //setLoading(false);
      })
      .catch((ex) => {
        console.log("Exception in LOGIN FORM ===> google sign in :", ex);
        setLoading(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Backdrop
        sx={{color: "#fff", zIndex: 1}}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{m: 1, bgcolor: "primary.main"}}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{mt: 3}}>
          <Collapse in={error !== ""}>
            <Alert sx={{margin: "0 0 10px 0"}} severity="error">
              {error}
            </Alert>
          </Collapse>

          <CustomButton
            variant="outlined"
            fullWidth
            onClick={handleGoogleSignIn}
          >
            Sign in with Google
          </CustomButton>

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            required
          />

          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 3, mb: 2}}
          >
            Sign In
          </CustomButton>

          <Grid container>
            <Grid item xs>
              <Typography variant="body2">
                <Link to="#" className="link">
                  Forgot password?
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{cursor: "pointer"}}
                variant="body2"
                onClick={handleOpen}
              >
                {/* <Link to="/signup" className="link"> */}
                Don't have an account? Sign Up
                {/* </Link> */}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Copyright sx={{mt: 1, mb: 2}} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {mobileScreen && (
            <IconButton sx={{}} onClick={handleClose}>
              <CloseIcon sx={{fontSize: "1.5rem", color: "darkgrey"}} />
            </IconButton>
          )}
          <SignUpPage redirect={redirect} />
        </Box>
      </Modal>
    </Container>
  );
}
