import {
  Box,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import React, {useState, useRef, useContext, useEffect} from "react";
import {Link} from "react-router-dom";
import StarRating from "./StarRating";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ImageCarousel from "../../common/ImageCarousel";
import CustomButton from "../../common/Button/CustomButton";
import CartItemsContext from "../../../context/cartItems/CartItemsContext";
import LikedItemsContext from "../../../context/likedItems/LikedItemsContext";
import CustomisedSnackbar from "../../common/Snackbar/CustomisedSnackbar";
import AllProductContext from "../../../context/allProducts/AllProductsContext";

const ProductCard = ({
  product,
  link,
  addToCartButton,
  showCarousel,
  fadeOutOnRemovingLike = false,
  showLikeButton = true,
  useMobileSize = false,
  showRating = true,
  showQuantity = false,
}) => {
  const initRating = {
    avg: 0,
    total: 0,
  };
  const [open, setOpen] = useState(false);
  const [liked, setLiked] = useState(false);
  const [rating, setRating] = useState(initRating);
  const snackbarMessage = useRef("");
  const cartItemsContext = useContext(CartItemsContext);
  const likedItemsContext = useContext(LikedItemsContext);
  const allProductContext = useContext(AllProductContext);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const loadImg = new Image();
      loadImg.src = image;
      // wait 1 second to simulate loading time
      loadImg.onload = () =>
        // setTimeout(() => {
        {
          // console.log("resolve:", image, ", proId:", product.id);
          resolve(image);
        };
      // }, 1000);

      loadImg.onerror = (err) => reject(err);
    });
  };
  useEffect(() => {
    const allProductsArr = allProductContext.state;

    // console.log(
    //   "Product Card =====> allProductContext.state: ",
    //   allProductsArr
    // );
    setRating(initRating);
    if (allProductsArr !== null && allProductsArr.length > 0) {
      const prod = allProductsArr.filter((item) => {
        return item.id === product.id;
      });
      if (prod[0].ratings && prod[0].ratings.length > 0) {
        const p_rating_average =
          prod[0].ratings.reduce((a, b) => a + b, 0) / prod[0].ratings.length;
        const rating_average = Math.round(p_rating_average / 0.5) * 0.5;
        const setData = {
          avg: rating_average,
          total: prod[0].ratings.length,
        };
        console.log("UPDATE PRO RATING====>", setData);
        setRating({
          ...setData,
        });
      } else {
        setRating({...initRating});
      }
    }
  }, [allProductContext.state]);

  useEffect(() => {
    setLiked(false);
    if (
      likedItemsContext.state !== null &&
      likedItemsContext.state.length > 0
    ) {
      const likedItemsArray = likedItemsContext.state;
      const indexOfProductInLikedItems = likedItemsArray.findIndex(
        (a) => a.id === product.id
      );
      // console.log("likedItemsArray:", likedItemsArray.length);

      if (indexOfProductInLikedItems !== -1) {
        setLiked(true);
      }
    }
  }, [likedItemsContext.state]);

  useEffect(() => {
    loadImage(product.images[0].url)
      .then(() => setLoaded(true))
      .catch((err) => setLoaded(true));
    setDeleteItemId(null);
    setLiked(false);
    if (likedItemsContext.state !== null) {
      const likedItemsArray = likedItemsContext.state;
      const indexOfProductInLikedItems = likedItemsArray.findIndex(
        (a) => a.id === product.id
      );
      if (indexOfProductInLikedItems !== -1) {
        setLiked(true);
      }
    }
  }, [product.id]);
  useEffect(() => {
    if (deleteItemId !== null) {
      setTimeout(() => {
        setOpen(true);
      }, 500);
    }
  }, [deleteItemId]);

  const handleAddToCart = () => {
    const tempCartItems =
      cartItemsContext.state !== null ? cartItemsContext.state : [];

    const indexOfProductInCart = tempCartItems.findIndex(
      (a) => a.id === product.id
    );

    // Check if this product already added to cart

    // console.log("indexOfProductInCart:", indexOfProductInCart);
    if (indexOfProductInCart !== -1) {
      const products_ = [...tempCartItems];
      products_.splice(indexOfProductInCart, 1);
      const tempItemCount = tempCartItems[indexOfProductInCart].quantity;
      cartItemsContext.setState([
        {...product, quantity: tempItemCount + 1},
        ...products_,
      ]);
      snackbarMessage.current =
        "1 more unit of " + product.title + " added to cart";
    } else {
      snackbarMessage.current = "1 unit of " + product.title + " added to cart";
      cartItemsContext.setState([{...product, quantity: 1}, ...tempCartItems]);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLikeItem = () => {
    const likedItemsArray = likedItemsContext.state;
    if (likedItemsArray !== null) {
      const indexOfProductInLikedItems = likedItemsArray.findIndex(
        (a) => a.id === product.id
      );
      if (indexOfProductInLikedItems !== -1) {
        fadeOutOnRemovingLike
          ? setDeleteItemId(product.id)
          : setDeleteItemId(null);
        snackbarMessage.current = product.title + " removed from wishlist";

        setLiked(false);
        likedItemsArray.splice(indexOfProductInLikedItems, 1);
        setTimeout(() => {
          likedItemsContext.setState([...likedItemsArray]);
        }, 500);
      } else {
        snackbarMessage.current = product.title + " added to wishlist";
        likedItemsContext.setState([product, ...likedItemsArray]);
        setLiked(true);
      }
    } else {
      snackbarMessage.current = product.title + " added to wishlist";
      likedItemsContext.setState([product]);
      setLiked(true);
    }
    if (!fadeOutOnRemovingLike) setOpen(true);
  };
  return (
    <Box
      className={`product-box-container cart-item-paper ${
        deleteItemId === product.id ? "fadeOut" : ""
      }`}
    >
      {showLikeButton && loaded && (
        <IconButton
          className="icon-float favorite-icon"
          onClick={() => handleLikeItem()}
        >
          <FavoriteIcon
            sx={{width: "1.1em", height: "1.1em"}}
            className={liked ? "favorite-icon-true" : "favorite-icon-false"}
          />
        </IconButton>
      )}
      {showQuantity && loaded && (
        <Box className="icon-float quantity-text">
          <Typography variant="caption" sx={{fontWeight: 500}}>
            {" "}
            Qty. {product.quantity}
          </Typography>
        </Box>
      )}
      <Link to={link}>
        {showCarousel && (
          <Box className="product-paper-container">
            <ImageCarousel
              showIndicators={false}
              ImagesArr={product.images.map((a) => a.url)}
              autoPlayOnHover={true}
              sx={{width: "100%"}}
            />
          </Box>
        )}
        {!showCarousel && loaded && (
          <Box
            className="product-paper-container bg-image"
            sx={{backgroundImage: `url(${product.images[0].url})`}}
          ></Box>
        )}
        {!showCarousel && !loaded && (
          <Skeleton
            className="product-paper-container bg-image no-before"
            sx={{transform: "scale(1)"}}
          />
        )}

        <Grid className="product-paper-heading" container wrap="nowrap">
          <Grid item xs zeroMinWidth>
            <Typography
              className="text"
              variant="body2"
              noWrap
              sx={{fontSize: useMobileSize ? "0.85rem" : ""}}
            >
              {loaded && product.title}
              {!loaded && <Skeleton />}
            </Typography>
          </Grid>
        </Grid>
      </Link>
      <Paper className="product-paper-heading">
        {loaded && showRating && (
          <StarRating rating={rating.avg} showTotalNumber={rating.total} />
        )}
        {!loaded && showRating && <Skeleton width="50%" />}
        <Typography className="list-price" variant="body">
          {loaded && <span>&#8377;{product.listPrice}</span>}
          {!loaded && <Skeleton width="25%" />}
        </Typography>
        {useMobileSize && (
          <Typography className="save-rupees" variant="caption">
            {loaded && (
              <span>
                Save &#8377;{Math.ceil(product.maxPrice - product.listPrice)}
              </span>
            )}
            {!loaded && <Skeleton width="25%" />}
          </Typography>
        )}
        {!useMobileSize && loaded && (
          <>
            <Typography className="discount-percent" variant="">
              <span>
                (
                {Math.ceil(
                  ((product.maxPrice - product.listPrice) / product.maxPrice) *
                    100
                )}
                % off)
              </span>
            </Typography>
            {/* <Typography variant="" className="mrp-text">
              M.R.P.
            </Typography> */}
            <Typography className="max-price" variant="">
              &#8377;{product.maxPrice}
            </Typography>
          </>
        )}
        {!useMobileSize && !loaded && (
          <>
            <Typography className="discount-percent" variant="">
              {!loaded && <Skeleton width="45%" />}
            </Typography>
          </>
        )}
      </Paper>
      {addToCartButton && (
        <CustomButton
          sx={{marginTop: "0.5rem"}}
          variant="outlined"
          size="small"
          fullWidth={true}
          onClick={() => {
            handleAddToCart();
          }}
        >
          Add to cart
        </CustomButton>
      )}

      <CustomisedSnackbar
        message={snackbarMessage.current}
        severity="success"
        open={open}
        handleClose={handleClose}
      />
    </Box>
  );
};

export default ProductCard;
