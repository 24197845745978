import {Container, Typography} from "@mui/material";
import React from "react";

const AboutPage = () => {
  return (
    <Container maxWidth="xl">
      <Typography variant="h4" mt="2rem">
        Welcome to {process.env.REACT_APP_NAME}, where nature meets convenience!
      </Typography>
      <Typography variant="p" component="p" mt="0.5rem">
        At {process.env.REACT_APP_NAME}, we are passionate about bringing the
        beauty of the plant kingdom to your doorstep. As avid plant enthusiasts
        ourselves, we understand the joy and tranquility that greenery can bring
        into your life.
      </Typography>
      <Typography variant="p" component="p" mt="0.5rem">
        Our journey began with a simple yet profound idea - to make plants
        accessible to everyone, no matter where they are. We believe that
        everyone should have the opportunity to experience the many benefits
        that plants bring, from improving air quality to enhancing overall
        well-being. What sets us apart is our commitment to offering a diverse
        selection of high-quality plants, carefully curated to cater to all
        levels of gardening expertise. Whether you're a seasoned green thumb or
        a first-time plant parent, we have something for everyone. From elegant
        succulents to lush tropical plants, our collection is designed to add a
        touch of nature to any space.
      </Typography>
      <Typography variant="p" component="p" mt="0.5rem">
        At
        {process.env.REACT_APP_NAME}, we prioritize your satisfaction and strive
        to provide an exceptional shopping experience. Our user-friendly website
        is designed to make browsing and purchasing your favorite plants a
        breeze. We also offer detailed care instructions and tips to ensure that
        your plants thrive in their new homes.
      </Typography>
      <Typography variant="p" component="p" mt="0.5rem">
        We source our plants from trusted growers who share our commitment to
        quality and sustainability. Each plant is inspected with care before it
        makes its way to you, ensuring that you receive a healthy and vibrant
        addition to your home or office.
      </Typography>
      <Typography variant="p" component="p" mt="0.5rem">
        Customer service is at the heart of our business. Our knowledgeable and
        friendly team is here to assist you at every step of your plant-buying
        journey. Whether you have questions about plant care, need assistance
        with your order, or simply want some advice on choosing the perfect
        plant, we're here for you.
      </Typography>
      <Typography variant="p" component="p" mt="0.5rem">
        Thank you for choosing {process.env.REACT_APP_NAME} as your go-to
        destination for all things green. Join us in embracing the beauty of
        nature and discover the joy of nurturing your very own indoor oasis.
        Happy planting!
      </Typography>
    </Container>
  );
};

export default AboutPage;
