import React from "react";
import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
const OrderSummary = ({cartItems}) => {
  console.log("CartItems in order summary:", cartItems);
  return (
    <Container
      sx={{padding: 0, overflowX: "hidden"}}
      component="main"
      maxWidth="xs"
    >
      <CssBaseline />
      <Box
        sx={{
          padding: 0,
          marginTop: "1rem",
          marginLeft: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={1} sx={{padding: 0, paddingRight: "1rem"}}>
          {cartItems.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Grid item xs={1}>
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{fontWeight: "400", fontSize: "0.8rem"}}
                  >
                    {index + 1}.
                  </Typography>
                </Grid>
                <Grid item xs={7} textAlign="left">
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{fontWeight: "400", fontSize: "0.8rem"}}
                    noWrap
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="caption" component="p">
                    Price: &#8377; {item.listPrice} x {item.quantity} no's
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography
                    variant="caption"
                    sx={{fontWeight: "400", marginRight: "5px"}}
                  >
                    Total
                  </Typography>
                  <Typography variant="caption">
                    &#8377;{item.quantity * item.listPrice}
                  </Typography>
                </Grid>
              </React.Fragment>
            );
          })}

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={8} textAlign="left" justifyContent="center">
            <Typography
              variant="body2"
              component="p"
              sx={{fontWeight: "400", fontSize: "0.8rem"}}
              noWrap
            >
              Shipping cost
            </Typography>
          </Grid>
          <Grid item xs={4} textAlign="right" justifyContent="center">
            <Typography variant="body2" component="p" sx={{fontSize: "0.8rem"}}>
              Free
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body1" sx={{fontWeight: "400 !important"}}>
              Total
            </Typography>
          </Grid>
          <Grid item xs={7} textAlign="right">
            <Typography variant="body1" sx={{fontWeight: "400 !important"}}>
              Final amount
            </Typography>
          </Grid>
          <Grid item xs={5} sx={{pt: "0 !important"}}>
            <Typography variant="body1">
              ({cartItems.reduce((a, v) => a + v.quantity, 0)} items)
            </Typography>
          </Grid>
          <Grid item xs={7} sx={{pt: "0 !important"}} textAlign="right">
            <Typography variant="body1">
              &#8377;{" "}
              {cartItems.reduce(
                (a, v) => (a = a + v.listPrice * v.quantity),
                0
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default OrderSummary;
