/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomButton from "../../../components/common/Button/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import ProductsAPI from "../../../api/firebase/ProductsAPI";
import { Avatar, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import AddCategory from "../../../components/admin/add-category/AddCategory";
import AllCategoriesContext from "../../../context/allCategories/AllCategoriesContext";
import { styles } from "./styles";
import CustomDataGrid from "../../../components/common/DataGrid/CustomDataGrid";
import DataGridActions from "../../../components/admin/datagrid-actions/DataGridActions";
import AddCategoryContext from "../../../context/addCategory/AddCategoryContext";
import ImageIcon from "@mui/icons-material/Image";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const CategoriesListAdmin = () => {
  const initState = {
    id: "",
    name: "",
    parentCategory: {
      id: "None",
      name: "None",
    },
    images: [],
    showInNavbar: true,
  };
  const productAPI = new ProductsAPI();
  const allCategoriesContext = useContext(AllCategoriesContext);
  console.log("all Categories :", allCategoriesContext.state);

  // Fetch all products and categories
  useEffect(() => {
    const productAPI = new ProductsAPI();
    const resCategories = productAPI.getCategories();
    resCategories.then((resData) => {
      // console.log("received:", resData);
      if (!resData) {
        return;
      }
      console.log("Done fetching all categories: ", resData);
      allCategoriesContext.setState(resData.data);
    });
  }, []);

  const categoryContext = useContext(AddCategoryContext);

  // For Add Category Modal
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const showAddCategoryModal = (action) => {
    if (action === "ADD" && editCategoryModal) {
      setEditCategoryModal(false);
      categoryContext.setState(initState);
    }
    setOpenCategoryModal(true);
  };
  const hideAddCategoryModal = () => setOpenCategoryModal(false);

  // For snackbar
  const [snackbarState, setSnackbarState] = useState(false);
  const [message, setMessage] = useState({ text: "", type: "" });
  const showSnackbar = () => {
    setSnackbarState(true);
  };
  const hideSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState(false);
  };

  //// set edit category
  const [editCategoryModal, setEditCategoryModal] = useState(false);

  const setEditCategory = (category) => {
    console.log("edit category:", category);
    setEditCategoryModal(true);
    categoryContext.setState(category);
    showAddCategoryModal("EDIT");
  };
  // Delete Category from firebase and update allCategoriesContext state
  const handleDeleteCategory = (cat_id) => {
    const res = productAPI.deleteCategory(cat_id);
    res
      .then((resData) => {
        if (resData.success) {
          setMessage({
            text: "Category deleted successfully !",
            type: "success",
          });
          showSnackbar();
          const categoryIndex = allCategoriesContext.state.findIndex(
            (obj) => obj.id === cat_id
          );
          const categories_ = [...allCategoriesContext.state];
          categories_.splice(categoryIndex, 1);
          allCategoriesContext.setState(categories_);
        }
      })
      .catch((ex) => {
        setMessage({
          text: "Some error occured. Please try again !!",
          type: "error",
        });
        console.log(ex);
      });
  };

  /// Add Category to firebase
  const handleSetCategory = (
    action = "ADD",
    category_id = null,
    data = categoryContext.state
  ) => {
    console.log("data:", data, " category_id before:", category_id);
    if (category_id === null) {
      category_id = data.name.trim().toUpperCase();
      category_id = category_id.substring(0, category_id.length);
      category_id = category_id.replace(/ /g, "_");
    }
    // console.log("category_id after:", category_id);

    // API to add product data to firestore
    const res = productAPI.setCategory({
      id: category_id,
      payload: data,
    });
    res
      .then((resData) => {
        if (resData.success) {
          let action_msg = action === "ADD" ? "added" : "updated";
          setMessage({
            text: "Category " + action_msg + " successfully !",
            type: "success",
          });
          showSnackbar();
          if (action === "ADD") {
            categoryContext.setState({
              ...data,
              id: category_id,
              createdAt: { seconds: Date.now() / 1000 },
            });

            allCategoriesContext.setState([
              {
                ...data,
                id: category_id,
                createdAt: { seconds: Date.now() / 1000 },
              },
              ...allCategoriesContext.state,
            ]);
          } else {
            const categoryIndex = allCategoriesContext.state.findIndex(
              (obj) => obj.id === category_id
            );
            const categories_ = [...allCategoriesContext.state];
            categories_[categoryIndex] = {
              ...categories_[categoryIndex],
              ...data,
            };
            allCategoriesContext.setState(categories_);
          }
          categoryContext.setState(initState);
          hideAddCategoryModal();
        } else {
          setMessage({
            text: "Some error occured. Please try again !!",
            type: "error",
          });
          console.log(resData.message);
        }
      })
      .catch((ex) => {
        setMessage({
          text: "Some error occured. Please try again !!",
          type: "error",
        });
        console.log(ex);
      });
  };

  //// Columns for Categories DataGrid
  const columns = [
    {
      field: "images",
      headerName: "Image",
      width: 60,
      renderCell: (params) => {
        if (params.row.images.length > 0) {
          return <Avatar src={params.row.images[0].url} />;
        }
        return (
          <Avatar>
            <ImageIcon />
          </Avatar>
        );
      },
      sortable: false,
      filterable: false,
    },
    { field: "name", headerName: "Title", width: 250 },
    {
      field: "parentCategory",
      headerName: "Parent Category",
      width: 250,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.parentCategory.name}</div>;
      },
    },
    {
      field: "createdAt",
      headerName: "Created at",
      width: 200,
      renderCell: (params) => {
        const t = new Date(params.row.createdAt.seconds * 1000).toLocaleString(
          "en-GB",
          {
            timeZone: "IST",
          }
        );
        return <div className="rowitem">{t}</div>;
      },
    },
    { field: "id", hide: true },
    {
      field: "showInNavbar",
      headerName: "Show In Navbar",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            {params.row.showInNavbar ? "Visible" : "Hidden"}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <DataGridActions
          {...{ params }}
          onEdit={setEditCategory}
          onDelete={handleDeleteCategory}
          onVisibilityChange={handleSetCategory}
        />
      ),
    },
  ];

  return (
    <Grid2 container spacing={2}>
      <Grid2>
        <CustomButton
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => showAddCategoryModal("ADD")}
        >
          Add Category
        </CustomButton>
      </Grid2>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openCategoryModal}
        onClose={hideAddCategoryModal}
        closeAfterTransition
      >
        <Fade in={openCategoryModal}>
          <Box sx={styles.boxStyle}>
            <AddCategory
              action={editCategoryModal ? "EDIT" : "ADD"}
              hideModal={hideAddCategoryModal}
              handleSetCategory={handleSetCategory}
            />
          </Box>
        </Fade>
      </Modal>

      <Snackbar
        open={snackbarState}
        autoHideDuration={3000}
        onClose={hideSnackbar}
      >
        <Alert
          onClose={hideSnackbar}
          severity={message.type}
          sx={{ width: "100%" }}
        >
          {message.text}
        </Alert>
      </Snackbar>

      <Grid2 xs={12} mt={2}>
        <CustomDataGrid
          rows={allCategoriesContext.state}
          columns={columns}
          styles={{ height: "540px" }}
          pageSizes={[10, 25, 50]}
        />
      </Grid2>
    </Grid2>
  );
};

export default CategoriesListAdmin;
