import {CssBaseline, Grid, TextField, Typography} from "@mui/material";
import React, {useContext, useState, useRef, useEffect} from "react";
import UserProfileContext from "../../context/userProfile/UserProfileContext";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {Link} from "react-router-dom";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input/input";
import CustomButton from "../../components/common/Button/CustomButton";
import UserDataAPI from "../../api/firebase/UserDataAPI";
import Loader from "../../components/loader/Loader";
import CustomisedSnackbar from "../../components/common/Snackbar/CustomisedSnackbar";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link to="/" className="link bold copyright-appname">
        {process.env.REACT_APP_NAME}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
function Terms(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="left" {...props}>
      By Continuing, you agree to {process.env.REACT_APP_NAME}'s{" "}
      <Link to="#" className="link bold">
        Terms and Conditions
      </Link>
      , and{" "}
      <Link to="#" className="link bold">
        Privacy Policy
      </Link>
    </Typography>
  );
}

const UserProfilePage = () => {
  const userProfileContext = useContext(UserProfileContext);
  const userDataApi = new UserDataAPI();
  const [error, setError] = useState("");
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const [mobileNum, setMobileNum] = useState();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [formError, setFormError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  useEffect(() => {
    console.log("userProfileContext.state:", userProfileContext.state);
    if (userProfileContext.state !== null) {
      setUser(userProfileContext.state);
    }
  }, [userProfileContext.state]);
  useEffect(() => {
    console.log("User:", user);
    if (user !== null) {
      nameRef.current.value = user.displayName;
      emailRef.current.value = user.email;
      user.mobile ? setMobileNum(user.mobile) : setMobileNum("");
    }
  }, [user]);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleSaveButtonClick = () => {
    setLoading(true);
    const data = {
      payload: {
        mobile: mobileNum,
        displayName: nameRef.current.value,
        email: emailRef.current.value,
      },
      id: user.id,
    };
    console.log("data to send:", data);
    userDataApi.addUserData(data).then((res) => {
      console.log("RES:", res);
      if (res.success) {
        userProfileContext.setState({...user, ...data.payload});
      }
      setOpenSnackbar(true);
      setLoading(false);
    });
  };
  const handleCancelButtonClick = () => {};
  return (
    <>
      {loading && <Loader />}
      <Container component="main" maxWidth="xs" sx={{minHeight: "90vh"}}>
        <CssBaseline />
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{mt: "2rem"}}
        >
          <Grid item xs={12} sx={{mb: "1rem"}}>
            <Typography variant="h5">Personal Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="given-name"
              name="name"
              required
              fullWidth
              id="name"
              label="Full Name"
              inputRef={nameRef}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              inputRef={emailRef}
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry="IN"
              value={mobileNum}
              onChange={setMobileNum}
              className="phone-input"
              maxLength={20}
              error={
                mobileNum
                  ? isValidPhoneNumber(mobileNum)
                    ? undefined
                    : "Invalid Phone number"
                  : "Phone number required"
              }
            />
            <Typography
              variant="body2"
              sx={{color: "red", padding: "0.35rem 0"}}
            >
              {mobileNum
                ? isValidPhoneNumber(mobileNum)
                  ? undefined
                  : "Invalid Phone number"
                : "Phone number required"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CustomButton fullWidth onClick={handleCancelButtonClick}>
              Cancel
            </CustomButton>
          </Grid>
          <Grid item xs={6}>
            <CustomButton
              fullWidth
              variant="contained"
              onClick={handleSaveButtonClick}
              disabled={formError === "" ? false : true}
            >
              Save
            </CustomButton>
          </Grid>
        </Grid>
        <CustomisedSnackbar
          message="Profile updated successfully"
          severity="success"
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
        />
      </Container>
    </>
  );
};

export default UserProfilePage;
