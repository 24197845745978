import React, {useState, useEffect} from "react";
import UserAddressesPage from "../../../pages/user/UserAddressesPage";

const SelectAddressStep = ({setCompleted, selAddress, setSelAddress}) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  useEffect(() => {
    console.log("selAddress:", selAddress);
    if (selAddress !== null && selAddress !== undefined) {
      setCompleted(true);
    }
  }, []);

  useEffect(() => {
    console.log("selectedAddress:", selectedAddress);
    if (selectedAddress !== null) {
      setCompleted(true);
      setSelAddress(selectedAddress);
    }
  }, [selectedAddress]);

  return (
    <UserAddressesPage
      selectAddress={true}
      selectedAddress={selAddress}
      setSelectedAddress={setSelectedAddress}
    />
  );
};

export default SelectAddressStep;
