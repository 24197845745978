import React, {useState, useContext} from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {drawerItems} from "./items";
import {drawerStyles} from "./styles";
import {useNavigate, Outlet, Link} from "react-router-dom";
import {
  AppBar,
  Box,
  CssBaseline,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import CustomButton from "../../../common/Button/CustomButton";
import UserProfileContext from "../../../../context/userProfile/UserProfileContext";
import AuthAPI from "../../../../api/firebase/AuthAPI";

import {useTheme} from "@mui/material/styles";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import LightModeIcon from "@mui/icons-material/LightMode";
import ColorModeContext from "../../../../context/colorMode/ColorModeContext";
import AdminDashboard from "../../../../pages/admin/dashboard";

const LeftResponsiveDrawer = (props) => {
  const theme = useTheme();
  const colorModeContext = React.useContext(ColorModeContext);
  const {window} = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Dashboard");
  const userProfileContext = useContext(UserProfileContext);
  const currentUser = userProfileContext.state;
  const authAPI = new AuthAPI();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleLogout = async () => {
    try {
      await authAPI
        .signoutUser()
        .then(() => {
          userProfileContext.setState(null);
          localStorage.clear();
          console.log("Sign out success");
          navigate("/");
        })
        .catch((err) => {
          console.log("Error in SignOut:", err);
        });
    } catch (ex) {
      console.log("Error sign out:", ex);
    }
  };
  const navigate = useNavigate();
  const drawer = (
    <div>
      <Box sx={drawerStyles.topBox}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 1,
            p: 0,
          }}
        >
          <IconButton
            sx={{
              width: "100%",
              "&:hover": {
                transform: "scale(1.02)",
                background: "none",
                transition: "all 0s ease",
              },
              borderRadius: 0,
            }}
            onClick={colorModeContext.toggleColorMode}
            color="inherit"
          >
            {theme.palette.mode === "dark" ? (
              <>
                <LightModeIcon />
                <Typography variant="body1" sx={{marginLeft: "5px"}}>
                  Light mode
                </Typography>
              </>
            ) : (
              <>
                <Brightness3Icon />
                <Typography variant="body1" sx={{marginLeft: "5px"}}>
                  Dark mode
                </Typography>
              </>
            )}
          </IconButton>
        </Box>
        <Divider sx={drawerStyles.divider} />
        <Typography variant="h5" sx={{margin: "0.5rem"}}>
          <Link to="/">{process.env.REACT_APP_NAME}</Link>
        </Typography>
        <Divider sx={drawerStyles.divider} />
        <Box sx={{marginTop: "10px"}}>
          <Avatar
            alt={currentUser !== null ? currentUser.displayName : "Admin"}
            src={
              currentUser !== null
                ? currentUser.photoURL
                : "/static/images/avatar/1.jpg"
            }
            sx={drawerStyles.avatar}
          />
          <Typography variant="body1">
            {currentUser !== null ? currentUser.displayName : "Admin"}
          </Typography>
          <CustomButton
            color="secondary"
            variant="contained"
            fullWidth
            sx={drawerStyles.logoutButton}
            onClick={handleLogout}
          >
            Logout
          </CustomButton>
        </Box>
      </Box>

      <Divider sx={drawerStyles.divider} />
      <List>
        {drawerItems.map((item, index) => (
          <ListItemButton
            sx={drawerStyles.listItems}
            key={item.id}
            onClick={() => {
              setSelectedTab(item.label);
              navigate(item.route);
              handleDrawerToggle();
            }}
          >
            <ListItemIcon sx={drawerStyles.icons}>{item.icon}</ListItemIcon>
            <ListItemText sx={drawerStyles.text} primary={item.label} />
          </ListItemButton>
        ))}
      </List>
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{display: "flex"}}>
      <CssBaseline enableColorScheme />
      <AppBar position="fixed" sx={drawerStyles.appBar}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={drawerStyles.appBarIcon}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" noWrap component="div">
            {selectedTab}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={drawerStyles.drawerBox}>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={drawerStyles.temporaryDrawer}
        >
          {drawer}
        </Drawer>
        <Drawer
          className={drawerStyles.drawer}
          variant="permanent"
          sx={drawerStyles.permanentDrawer}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box component="main" sx={drawerStyles.outletBox}>
        <Toolbar sx={{display: {sm: "none", xs: "block"}}} />
        <Box component="div" sx={{display: {xs: "none", sm: "block"}}}>
          <Typography
            sx={{
              marginBottom: "2rem",
            }}
            variant="h4"
            component="h4"
          >
            {selectedTab}
          </Typography>
        </Box>
        {selectedTab === "Dashboard" ? <AdminDashboard /> : <Outlet />}
      </Box>
    </Box>
  );
};

export default LeftResponsiveDrawer;
