import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, {useState, useContext, useEffect} from "react";
import CustomButton from "../../common/Button/CustomButton";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Grid2 from "@mui/material/Unstable_Grid2/";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
// Assets
import AddProductImage from "../../../assets/images/placeholder/add-product.jpg";
// Firebase
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {storage} from "../../../firebase";
import "./styles.scss";

// Image Libraries

import FileUpload from "../../common/FileUpload";
import CompressAPI from "../../../api/compressImage/CompressAPI";
import AddProductContext from "../../../context/addProduct/AddProductContext";
import AllCategoriesContext from "../../../context/allCategories/AllCategoriesContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const AddProduct = ({hideModal, handleSetProduct, action}) => {
  const productContext = useContext(AddProductContext);
  const allCategoriesContext = useContext(AllCategoriesContext);
  const allCategoriesArr = allCategoriesContext.state;

  const [selSubCategories, setSelSubCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  console.log("allCategoriesArr:", allCategoriesArr);
  console.log("productContext:", productContext.state);
  const parentCategories = allCategoriesArr.filter((cat) => {
    return cat.parentCategory.id === "None";
  });
  let initProImages = [];
  if (action === "EDIT") {
    initProImages = productContext.state.images;
  }
  const [imageData, setImageData] = useState(initProImages);
  const [progress, setProgress] = useState(0);
  const titleInputLimit = {
    minLength: 5,
    maxLength: 200,
  };
  const priceInputLimit = {
    min: 1,
    max: 100000,
  };
  const unitsInStockInputLimit = {
    min: 1,
    max: 1000,
  };
  useEffect(() => {
    const _selSubCategories = productContext.state.categories;
    setSelSubCategories(_selSubCategories);
    const selCatId = productContext.state.parentCategory.id;
    const _subCategories = allCategoriesArr.filter(
      (e) => e.parentCategory.id === selCatId
    );
    setSubCategories(_subCategories);
  }, []);

  const handleChangeCategory = (event) => {
    setSelSubCategories([]);
    const selCatId = event.target.value;
    if (selCatId === "NONE") {
      productContext.setState({
        ...productContext.state,
        parentCategory: {
          id: "NONE",
          name: "None",
        },
      });
      setSubCategories([]);
      return;
    }
    // Get Category detail
    const categoryDetail = parentCategories.find((e) => e.id === selCatId);
    // find categories from allCategoriesArray, where parentCategory is selCatId
    const subCategoriesArr = allCategoriesArr.filter(
      (e) => e.parentCategory.id === selCatId
    );
    // set subCategoriesArr to state
    setSubCategories(subCategoriesArr);
    productContext.setState({
      ...productContext.state,
      parentCategory: {
        id: categoryDetail.id,
        name: categoryDetail.name,
        images: categoryDetail.images,
        showInNavbar: categoryDetail.showInNavbar,
      },
    });
  };
  const handleChangeSubcategory = (event) => {
    // Get Parent Category
    const {
      target: {value},
    } = event;
    // On autofill we get a stringified value.
    const tempSubcategories =
      typeof value === "string" ? value.split(",") : value;

    setSelSubCategories(tempSubcategories);
    productContext.setState({
      ...productContext.state,
      categories: tempSubcategories,
    });
  };
  function handleFormData(formData) {
    productContext.setState({...productContext.state, ...formData});
  }
  function handleImageChange(e) {
    setProgress(0);
    setIsUploading(true);
    const upImage = e.target.files[0];
    if (upImage) {
      // console.log("image:", upImage);
      // Compress Image and get BLOB
      const compressProps = {
        size: 2, // the max size in MB, defaults to 2MB
        quality: 0.8, // the quality of the image, max is 1,
        maxWidth: 900, // the max width of the output image, defaults to 1920px
        maxHeight: 900, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
        rotate: true, // Enables rotation, defaults to false
      };
      const imgCompressor = new CompressAPI();
      const req = imgCompressor.compressImage(upImage, compressProps);
      req
        .then((resData) => {
          if (!resData.success) {
            setIsUploading(false);
            return;
          }
          const imgBlob = resData.data;
          // console.log("compressed img blob data:", imgBlob);
          // Set blob data to images state
          setImageData((prevState) => {
            if (prevState) {
              return [...prevState, imgBlob];
            } else {
              return imgBlob;
            }
          });

          ///// Convert base64 to file /////////
          const fileName = imgBlob.alt;
          const base64str = imgBlob.data;
          const imgExt = imgBlob.ext;
          const reqData = imgCompressor.base64ToImage(base64str, imgExt);
          if (!reqData.success) {
            setIsUploading(false);
            return;
          }
          const file = reqData.data;
          handleFileUpload({
            path: "images/products/",
            file,
            fileName,
          });
        })
        .catch((ex) => {
          setIsUploading(false);
          console.log("CompressImage Error:", ex);
        });
    }
  }
  const handleFileUpload = ({path, file, fileName}) => {
    // console.log("path:", path, " file:", file);
    fileName = fileName.replace(/[()]/g, "");
    const storageRef = ref(storage, `${path}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log("Upload is " + progress + "% done");
        setProgress(prog);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        setIsUploading(false);
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized": // User doesn't have permission to access the object
            console.log("Upload Error: unauthorized access");
            alert("Upload Error: unauthorized access");
            break;
          case "storage/canceled": // User canceled the upload
            console.log("Upload Error: Upload is cancelled");
            alert("Upload Error: Upload is cancelled");
            break;
          case "storage/unknown": // Unknown error occurred, inspect error.serverResponse
            console.log("Upload Error: Unknown error occurred");
            alert("Upload Error: Unknown error occurred");
            break;
          default:
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            console.log("File available at", downloadURL);
            setIsUploading(false);
            productContext.setState({
              ...productContext.state,
              images: [
                ...productContext.state.images,
                {name: fileName, url: downloadURL},
              ],
            });
          })
          .catch((ex) => {
            setIsUploading(false);
            alert("Error while fetching image url: ", ex);
          });
      }
    );
  };
  const handleDeleteFile = (index, fileName) => {
    console.log("delete file:", index, " name:", fileName);
    const tempImageData = [...imageData];
    tempImageData.splice(index, 1);
    setImageData(tempImageData);
    const fileRef = ref(storage, `images/products/${fileName}`);
    // Delete the file
    deleteObject(fileRef)
      .then(() => {
        // File deleted successfully
        console.log("deleted successfully");
        const updatedURLs = productContext.state.images.filter(
          (state) => state.name !== fileName
        );
        productContext.setState({
          ...productContext.state,
          images: updatedURLs,
        });
      })
      .catch((err) => {
        // Uh-oh, an error occurred!
        console.log("delete err:", err.message);
      });
  };
  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <FormControl fullWidth>
          <InputLabel id="category">Select category</InputLabel>
          <Select
            labelId="category"
            id="category"
            value={productContext.state.parentCategory.id}
            label="Select category"
            onChange={handleChangeCategory}
          >
            <MenuItem value="NONE">Select category</MenuItem>
            {parentCategories.map((data) => {
              return (
                <MenuItem key={data.id} value={data.id}>
                  {data.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid2>
      {subCategories.length > 0 && (
        <Grid2 xs={12}>
          <FormControl fullWidth>
            <Select
              multiple
              displayEmpty
              value={selSubCategories}
              onChange={handleChangeSubcategory}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return "Select Sub category";
                }
                return selected.join(", ").replace(/_/g, " ");
              }}
              MenuProps={MenuProps}
              inputProps={{"aria-label": "Without label"}}
            >
              {subCategories.map((cat) => (
                <MenuItem key={cat.id} value={cat.id}>
                  <Checkbox checked={selSubCategories.indexOf(cat.id) > -1} />
                  <ListItemText primary={cat.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>
      )}
      <Grid2 xs={12}>
        <TextField
          fullWidth
          id="id"
          label="Product Id or SKU (from amazon, flipkart or others, if any)"
          variant="standard"
          value={productContext.state.id}
          onChange={(e) => {
            handleFormData({id: e.target.value});
          }}
          inputProps={titleInputLimit}
          disabled={action === "ADD" ? false : true}
        />
      </Grid2>
      <Grid2 xs={12}>
        <TextField
          required
          fullWidth
          id="title"
          label="Title"
          variant="standard"
          value={productContext.state.title}
          onChange={(e) => {
            handleFormData({title: e.target.value});
          }}
          inputProps={titleInputLimit}
        />
      </Grid2>
      <Grid2 xs={12}>
        <TextField
          required
          fullWidth
          id="searchTerms"
          label="Search terms"
          variant="standard"
          value={productContext.state.searchTerms}
          onChange={(e) => {
            handleFormData({searchTerms: e.target.value});
          }}
          inputProps={titleInputLimit}
        />
      </Grid2>
      <Grid2 xs={4}>
        <TextField
          required
          fullWidth
          type="number"
          name="maxPrice"
          id="maxPrice"
          label="MRP"
          variant="standard"
          value={productContext.state.maxPrice}
          onChange={(e) => handleFormData({maxPrice: Number(e.target.value)})}
          inputProps={priceInputLimit}
        />
      </Grid2>
      <Grid2 xs={4}>
        <TextField
          required
          fullWidth
          type="number"
          name="listPrice"
          id="listPrice"
          label="List Price (Your selling price)"
          variant="standard"
          value={productContext.state.listPrice}
          onChange={(e) => handleFormData({listPrice: Number(e.target.value)})}
          inputProps={priceInputLimit}
        />
      </Grid2>
      <Grid2 xs={4}>
        <TextField
          required
          fullWidth
          type="number"
          name="unitsInStock"
          id="unitsInStock"
          label="Units (In stock)"
          variant="standard"
          value={productContext.state.unitsInStock}
          onChange={(e) =>
            handleFormData({unitsInStock: Number(e.target.value)})
          }
          inputProps={unitsInStockInputLimit}
        />
      </Grid2>
      <Grid2 xs={3}>
        <TextField
          required
          fullWidth
          type="number"
          name="netQuantity"
          id="netQuantity"
          label="Net Quantity"
          variant="standard"
          value={productContext.state.netQuantity}
          onChange={(e) =>
            handleFormData({netQuantity: Number(e.target.value)})
          }
          inputProps={priceInputLimit}
        />
      </Grid2>

      <Grid2 xs={3}>
        {/* <FormControl fullWidth>
          <InputLabel id="unitOfQuantity">Select unit</InputLabel>
          <Select
            labelId="unitOfQuantity"
            id="unitOfQuantity"
            value={productContext.state.parentCategory.id}
            label="Select unit of quantity"
            onChange={handleChangeCategory}
          >
            <MenuItem value="NONE">Select unit</MenuItem>
          </Select>
        </FormControl> */}
        <FormControl variant="standard" sx={{minWidth: 120}}>
          <InputLabel id="demo-simple-select-standard-label">
            Unit type
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={productContext.state.unitType}
            onChange={(e) => handleFormData({unitType: e.target.value})}
            label="Unit"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Grams">Grams</MenuItem>
            <MenuItem value="Kilograms">Kilograms</MenuItem>
            <MenuItem value="Litres">Litres</MenuItem>
            <MenuItem value="Milliliters">Milliliters</MenuItem>
            <MenuItem value="Set">Set</MenuItem>
            <MenuItem value="Pack">Pack</MenuItem>
            <MenuItem value="Piece">Piece</MenuItem>
          </Select>
        </FormControl>
      </Grid2>

      <Grid2 xs={12}>
        {imageData.map((img, index) => {
          const bgImg =
            img.url !== undefined
              ? `url(${img.url})`
              : `url(${img.prefix}${img.data})`;
          // console.log("img.url:", img.url, "__ bg img:", bgImg);
          return (
            <label className="uploaded-product" key={index}>
              <Paper
                style={{
                  backgroundImage: bgImg,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Tooltip title="Delete">
                  <IconButton
                    className="action-button"
                    aria-label="delete"
                    onClick={() => {
                      if (window.confirm("Sure to delete?"))
                        handleDeleteFile(index, img.alt || img.name);
                    }}
                  >
                    <DeleteIcon className="action-icons delete-icon" />
                  </IconButton>
                </Tooltip>
              </Paper>
            </label>
          );
        })}
        <FileUpload
          handleChange={handleImageChange}
          // bgImageSrc="https://via.placeholder.com/150"
          bgImageSrc={AddProductImage}
          upProgress={progress}
        />
      </Grid2>
      <Grid2 xs={12}>
        <p>Description</p>
        <CKEditor
          editor={ClassicEditor}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            editor.setData(productContext.state.description);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            handleFormData({description: data});
          }}
        />
      </Grid2>

      <CustomButton
        variant="contained"
        color="primary"
        size="small"
        onClick={() => handleSetProduct(action, productContext.state.id)}
        fullWidth
        sx={{marginTop: "15px"}}
        disabled={isUploading}
      >
        {action === "ADD" ? "Add Product" : "Update Product"}
      </CustomButton>

      {action === "EDIT" && (
        <CustomButton
          variant="outlined"
          color="primary"
          size="small"
          onClick={hideModal}
          fullWidth
          sx={{marginTop: "15px"}}
        >
          Cancel
        </CustomButton>
      )}
    </Grid2>
  );
};

export default AddProduct;
