import React, {useContext} from "react";
import LoginPage from "../../../pages/authentication/login";
import {
  Avatar,
  Box,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from "@mui/material";
import AuthAPI from "../../../api/firebase/AuthAPI";
import UserProfileContext from "../../../context/userProfile/UserProfileContext";
import CustomButton from "../../common/Button/CustomButton";
import Loader from "../../loader/Loader";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const LoginStep = ({user = null, loading}) => {
  console.log("Login Step =====>  user:", user, " loading:", loading);
  const authAPI = new AuthAPI();
  const userProfileContext = useContext(UserProfileContext);
  const handleLogout = async () => {
    try {
      await authAPI
        .signoutUser()
        .then(() => {
          userProfileContext.setState(null);
        })
        .catch((err) => {
          console.log("Error in SignOut:", err);
        });
    } catch (ex) {
      console.log("Error sign out:", ex);
    }
  };
  return (
    <div>
      {user !== null && !loading && (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{m: 1, bgcolor: "primary.main"}}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Signed In
            </Typography>
            <Box component="form" sx={{mt: 3}}>
              <TextField
                autoComplete="given-name"
                name="name"
                required
                fullWidth
                id="name"
                label="Full Name"
                value={user.displayName}
                inputProps={{readOnly: true}}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={user.email}
                inputProps={{readOnly: true}}
              />

              <CustomButton
                fullWidth
                variant="outlined"
                sx={{mt: 3, mb: 2}}
                onClick={handleLogout}
              >
                Use another account
              </CustomButton>
            </Box>
          </Box>
        </Container>
      )}
      {user === null && !loading && <LoginPage redirect={false} />}
      {loading && <Loader />}
    </div>
  );
};

export default LoginStep;
