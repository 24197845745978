import {
  Box,
  CssBaseline,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {useContext, useState, useRef, useEffect} from "react";
import UserProfileContext from "../../context/userProfile/UserProfileContext";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {Link} from "react-router-dom";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input/input";
import CustomButton from "../../components/common/Button/CustomButton";
import UserDataAPI from "../../api/firebase/UserDataAPI";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {states} from "./IndianStates";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RadioButtonUncheckedSharpIcon from "@mui/icons-material/RadioButtonUncheckedSharp";
import RadioButtonCheckedSharpIcon from "@mui/icons-material/RadioButtonCheckedSharp";
import AlertDialogSlide from "../../components/common/Dialog/AlertDialogSlide";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {md: "50%", sm: "75%", xs: "90%"},
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 10,
  p: 2, //padding
  maxHeight: "95%",
  overflow: "scroll",
};
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link to="/" className="link bold copyright-appname">
        {process.env.REACT_APP_NAME}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
function Terms(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="left" {...props}>
      By Continuing, you agree to {process.env.REACT_APP_NAME}'s{" "}
      <Link to="#" className="link bold">
        Terms and Conditions
      </Link>
      , and{" "}
      <Link to="#" className="link bold">
        Privacy Policy
      </Link>
    </Typography>
  );
}

const UserAddressesPage = ({
  selectAddress = false,
  selectedAddress = {},
  setSelectedAddress = () => {},
}) => {
  // console.log("States:", states);
  const userProfileContext = useContext(UserProfileContext);
  const userDataApi = new UserDataAPI();
  const [error, setError] = useState("");
  const nameRef = useRef(null);
  const pincodeRef = useRef(null);
  const addressRef = useRef(null);
  const cityRef = useRef(null);
  const [stateAddress, setStateAddress] = useState("");
  const landmarkRef = useRef(null);
  const [alternateMobileNum, setAlternateMobileNum] = useState();
  const [mobileNum, setMobileNum] = useState();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [tempAddress, setTempAddress] = useState(null);
  const [formError, setFormError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const itemToRemoveRef = useRef({id: "", title: ""});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchorEl = Boolean(anchorEl);
  const handleClickAnchorEl = ({index, event}) => {
    setAnchorEl(event.currentTarget);
    // console.log("Index:", index);
    setStateAddress(addresses[index].state);
    const t_address = {
      index: index,
      isDefault:
        addresses[index].isDefault !== "undefined"
          ? addresses[index].isDefault
          : false,
      name: addresses[index].name,
      mobileNum: addresses[index].mobile,
      address: addresses[index].address,
      city: addresses[index].city,
      stateAddress: addresses[index].state,
      landmark: addresses[index].landmark,
      alternateMobileNum: addresses[index].alternateMobile,
      pincode: addresses[index].pincode,
    };
    setTempAddress(t_address);
    // console.log("t_address:", t_address);
  };
  const handleCloseAnchorEl = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    console.log("userProfileContext.state:", userProfileContext.state);
    if (userProfileContext.state !== null) {
      setUser(userProfileContext.state);
      if (userProfileContext.state.addresses) {
        setAddresses(userProfileContext.state.addresses);
      }
    }
  }, [userProfileContext.state]);
  useEffect(() => {
    console.log("User:", user);
    if (user !== null) {
      user.mobile ? setMobileNum(user.mobile) : setMobileNum("");
    }
  }, [user]);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleAgreeDialog = () => {
    setOpenDialog(false);
    // setDeleteItemId(itemToRemoveRef.current.id);
    // Delete address
    // DELETE ADDRESS using index from addresses array
    const prevAddresses = addresses;
    prevAddresses.splice(tempAddress.index, 1);
    const data = {
      payload: {
        addresses: [...prevAddresses.reverse()],
      },
      id: user.id,
    };
    userDataApi
      .addUserData(data)
      .then((res) => {
        console.log("RES:", res);
        if (res.success) {
          userProfileContext.setState({...user, ...data.payload});
        }
        setLoading(false);
        handleClose();
      })

      .catch((err) => {
        setLoading(false);
        console.log("ERR:", err);
      });
  };
  const handleUpdateAddresses = ({method = "ADD", index = 0}) => {
    setLoading(true);
    if (selectAddress) {
      setSelectedAddress(null);
    }
    const newAddress = {
      isDefault: false,
      name: nameRef.current.value,
      mobile: mobileNum,
      address: addressRef.current.value,
      city: cityRef.current.value,
      state: stateAddress,
      landmark: landmarkRef.current.value || "",
      alternateMobile: alternateMobileNum || "",
      pincode: pincodeRef.current.value,
    };
    const prevAddresses =
      userProfileContext.state.addresses !== undefined
        ? userProfileContext.state.addresses
        : [];
    if (method === "ADD") {
      prevAddresses.push({...newAddress});
    } else {
      prevAddresses[index] = {
        ...newAddress,
        isDefault: prevAddresses[index].isDefault,
      };
    }

    const data = {
      payload: {
        addresses: [...prevAddresses],
      },
      id: user.id,
    };
    userDataApi
      .addUserData(data)
      .then((res) => {
        console.log("RES:", res);
        if (res.success) {
          userProfileContext.setState({...user, ...data.payload});
        }
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
        console.log("ERR:", err);
      });
  };
  const handleCancelButtonClick = () => {
    handleClose();
  };
  const handleEditAddress = (method = "EDIT") => {
    // Start editing address
    if (method === "EDIT") {
      handleOpen();
    } else {
      setOpenDialog(true);
    }
  };
  const handleMakeDefault = ({index}) => {
    setLoading(true);
    console.log("index:", index);
    const prevAddresses = [...addresses];
    prevAddresses[index].isDefault = true;
    //make isDefault false for other indexes of this array
    prevAddresses.forEach((address, i) => {
      if (i !== index) {
        address.isDefault = false;
      }
    });
    console.log("prevAddresses:", prevAddresses);
    setAddresses(prevAddresses);
    const data = {
      payload: {
        addresses: [...prevAddresses],
      },
      id: user.id,
    };
    userDataApi
      .addUserData(data)
      .then((res) => {
        console.log("RES:", res);
        if (res.success) {
          userProfileContext.setState({...user, ...data.payload});
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("ERR:", err);
      });
  };
  return (
    <Container component="main" maxWidth="md">
      <Backdrop
        sx={{color: "#fff", zIndex: 1}}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{mt: "2rem"}}
      >
        {!selectAddress && (
          <>
            <Grid item xs={12} sx={{mb: "1rem"}}>
              <Typography variant="h5">
                Manage Addresses ({addresses.length})
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{mb: "2rem"}}>
              <CustomButton
                variant="outlined"
                fullWidth
                startIcon={<AddOutlinedIcon></AddOutlinedIcon>}
                onClick={() => {
                  setTempAddress(null);
                  handleOpen();
                }}
              >
                Add a new Address
              </CustomButton>
            </Grid>
          </>
        )}
        {addresses.length > 0 &&
          addresses.map((address, _index) => {
            return (
              <Paper
                className="address-box"
                key={_index}
                onClick={() => {
                  if (selectAddress) setSelectedAddress(address);
                }}
                sx={{
                  marginLeft: selectAddress ? 0 : "",
                }}
              >
                <Grid container spacing={2}>
                  {selectAddress && (
                    <Grid
                      item
                      xs={2}
                      sm={1}
                      textAlign="left"
                      sx={{paddingLeft: "8px !important"}}
                    >
                      <IconButton
                        color="primary"
                        onClick={() => setSelectedAddress(address)}
                        sx={{padding: "4px"}}
                      >
                        {selectedAddress === address && (
                          <RadioButtonCheckedSharpIcon sx={{fontSize: 22}} />
                        )}
                        {selectedAddress !== address && (
                          <RadioButtonUncheckedSharpIcon sx={{fontSize: 22}} />
                        )}
                      </IconButton>
                    </Grid>
                  )}
                  <Grid
                    container
                    item
                    xs={selectAddress ? 10 : 12}
                    sm={selectAddress ? 11 : 12}
                    sx={{
                      paddingLeft: selectAddress ? "0 !important" : "16px",
                    }}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={10}>
                        <Typography
                          variant="h6"
                          component="p"
                          sx={{fontWeight: 400}}
                        >
                          {address.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={2} sx={{textAlign: "right"}}>
                        <Tooltip title="Edit/Remove Address">
                          <IconButton
                            onClick={(e) =>
                              handleClickAnchorEl({index: _index, event: e})
                            }
                            size="small"
                            sx={{ml: 2}}
                            aria-controls={
                              openAnchorEl ? "account-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openAnchorEl ? "true" : undefined}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" component="p">
                          {address.address}, {address.city}, {address.state} -{" "}
                          {address.pincode}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="p"
                          sx={{marginTop: "0.5rem"}}
                        >
                          {address.mobile}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} textAlign="right">
                        {!address.isDefault && !selectAddress && (
                          <CustomButton
                            size="small"
                            onClick={() =>
                              handleMakeDefault({
                                index: _index,
                              })
                            }
                          >
                            <Typography variant="caption">
                              Make default
                            </Typography>
                          </CustomButton>
                        )}
                        {address.isDefault && !selectAddress && (
                          <CustomButton variant="contained" size="small">
                            <Typography variant="caption">Default</Typography>
                          </CustomButton>
                        )}
                      </Grid>
                    </Grid>
                    <Menu
                      key={_index}
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={openAnchorEl}
                      onClose={handleCloseAnchorEl}
                      onClick={handleCloseAnchorEl}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{horizontal: "right", vertical: "top"}}
                      anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                    >
                      <MenuItem
                        sx={{fontSize: "0.8rem"}}
                        onClick={() => handleEditAddress()}
                        dense
                      >
                        <Typography variant="body2">Edit</Typography>
                      </MenuItem>
                      <MenuItem
                        sx={{fontSize: "0.8rem"}}
                        onClick={() => handleEditAddress({method: "DELETE"})}
                        dense
                      >
                        <Typography variant="body2">Remove</Typography>
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}

        {selectAddress && (
          <Grid item xs={12} sx={{mb: "1rem", padding: "0 !important"}}>
            <CustomButton
              variant="outlined"
              fullWidth
              startIcon={<AddOutlinedIcon></AddOutlinedIcon>}
              onClick={() => {
                setTempAddress(null);
                handleOpen();
              }}
            >
              Add a new Address
            </CustomButton>
          </Grid>
        )}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {tempAddress !== null ? "EDIT ADDRESS" : "ADD A NEW ADDRESS"}
          </Typography>
          <Box id="modal-modal-description" sx={{mt: 2}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{color: "darkred"}}>
                  {formError}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Full Name"
                  defaultValue={tempAddress !== null ? tempAddress.name : ""}
                  inputRef={nameRef}
                  onChange={(e) => {
                    e.target.value.length < 3
                      ? setFormError("Name must be 3 chars long")
                      : setFormError("");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneInput
                  placeholder="Phone number *"
                  defaultCountry="IN"
                  value={
                    tempAddress !== null ? tempAddress.mobileNum : mobileNum
                  }
                  onChange={setMobileNum}
                  className="phone-input"
                  maxLength={20}
                  error={
                    mobileNum
                      ? isValidPhoneNumber(mobileNum)
                        ? undefined
                        : "Invalid Phone number"
                      : "Phone number required"
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  autoComplete="address"
                  name="address"
                  required
                  fullWidth
                  id="address"
                  label="Address (Area and Street)"
                  defaultValue={tempAddress !== null ? tempAddress.address : ""}
                  inputRef={addressRef}
                  multiline
                  maxRows={2}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="address-level2"
                  name="city"
                  required
                  fullWidth
                  id="city"
                  label="City/District/Town"
                  defaultValue={tempAddress !== null ? tempAddress.city : ""}
                  inputRef={cityRef}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="address-level1"
                  select
                  name="state"
                  required
                  fullWidth
                  id="state"
                  label="State"
                  onChange={(e) => {
                    console.log("Ch:", e.target.value);
                    setStateAddress(e.target.value);
                  }}
                  defaultValue={
                    tempAddress !== null
                      ? tempAddress.stateAddress
                      : stateAddress
                  }
                  value={stateAddress}
                >
                  {states.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="landmark"
                  name="landmark"
                  fullWidth
                  id="landmark"
                  label="Landmark (Optional)"
                  inputRef={landmarkRef}
                  defaultValue={
                    tempAddress !== null ? tempAddress.landmark : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneInput
                  placeholder="Alternate Phone (Optional)"
                  defaultCountry="IN"
                  value={
                    tempAddress !== null
                      ? tempAddress.alternateMobileNum
                      : alternateMobileNum
                  }
                  onChange={setAlternateMobileNum}
                  className="phone-input"
                  maxLength={20}
                  error={
                    alternateMobileNum
                      ? isValidPhoneNumber(alternateMobileNum)
                        ? undefined
                        : "Invalid Phone number"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="postal-code"
                  name="pincode"
                  required
                  fullWidth
                  id="pincode"
                  label="Pincode"
                  inputRef={pincodeRef}
                  defaultValue={tempAddress !== null ? tempAddress.pincode : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={6}>
                <CustomButton fullWidth onClick={handleCancelButtonClick}>
                  Cancel
                </CustomButton>
              </Grid>
              <Grid item xs={6}>
                <CustomButton
                  fullWidth
                  variant="contained"
                  onClick={() =>
                    handleUpdateAddresses(
                      tempAddress !== null
                        ? {method: "UPDATE", index: tempAddress.index}
                        : {method: "ADD"}
                    )
                  }
                  disabled={formError === "" ? false : true}
                >
                  Save
                </CustomButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <AlertDialogSlide
        handleOnAgree={handleAgreeDialog}
        handleOnClose={handleCloseDialog}
        isOpen={openDialog}
        title="Remove Address"
        description={"Sure to remove this address ?"}
      />
    </Container>
  );
};

export default UserAddressesPage;
