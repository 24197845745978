import {
  FormControl,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import React, {useContext, useState} from "react";
import CustomButton from "../../common/Button/CustomButton";
import Grid2 from "@mui/material/Unstable_Grid2/";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AllCategoriesContext from "../../../context/allCategories/AllCategoriesContext";
import AddCategoryContext from "../../../context/addCategory/AddCategoryContext";
import DeleteIcon from "@mui/icons-material/Delete";

//Firebase
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {storage} from "../../../firebase";
// Image Libraries
import FileUpload from "../../common/FileUpload";
import CompressAPI from "../../../api/compressImage/CompressAPI";
// Assets
import AddProductImage from "../../../assets/images/placeholder/add-product.jpg";
const AddCategory = ({hideModal, handleSetCategory, action}) => {
  const allCategoriesContext = useContext(AllCategoriesContext);
  const categoryContext = useContext(AddCategoryContext);
  const allCategoriesArr = allCategoriesContext.state;
  const [isUploading, setIsUploading] = useState(false);
  console.log("categoryContext:", categoryContext.state);
  let initProImages = [];
  if (action === "EDIT") {
    initProImages = categoryContext.state.images;
  }
  const [imageData, setImageData] = useState(initProImages);
  const [progress, setProgress] = useState(0);
  const handleChange = (event) => {
    if (event.target.value === "None") {
      categoryContext.setState({
        ...categoryContext.state,
        parentCategory: {
          id: "None",
          name: "None",
        },
      });
    }
    allCategoriesArr.map((cat) => {
      if (cat.id === event.target.value) {
        categoryContext.setState({
          ...categoryContext.state,
          parentCategory: {
            id: cat.id,
            name: cat.name,
          },
        });
      }
      return 0;
    });
  };
  const titleInputLimit = {
    minLength: 2,
    maxLength: 50,
  };
  function handleImageChange(e) {
    setIsUploading(true);
    setProgress(0);
    const upImage = e.target.files[0];
    if (upImage) {
      // console.log("image:", upImage);
      // Compress Image and get BLOB
      const compressProps = {
        size: 5, // the max size in MB, defaults to 2MB
        quality: 0.8, // the quality of the image, max is 1,
        maxWidth: 900, // the max width of the output image, defaults to 1920px
        maxHeight: 900, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
        rotate: true, // Enables rotation, defaults to false
      };
      const imgCompressor = new CompressAPI();
      const req = imgCompressor.compressImage(upImage, compressProps);
      req
        .then((resData) => {
          if (!resData.success) {
            return;
          }
          const imgBlob = resData.data;

          // console.log("compressed img blob data:", imgBlob);
          // Set blob data to images state
          setImageData((prevState) => {
            if (prevState) {
              return [...prevState, imgBlob];
            } else {
              return imgBlob;
            }
          });

          ///// Convert base64 to file /////////
          const fileName = imgBlob.alt;
          const base64str = imgBlob.data;
          const imgExt = imgBlob.ext;
          const reqData = imgCompressor.base64ToImage(base64str, imgExt);
          if (!reqData.success) {
            return;
          }
          const file = reqData.data;
          // console.log("base64ToImage Res:", file);
          handleFileUpload({
            path: "images/categories/",
            file,
            fileName,
          });
        })
        .catch((ex) => {
          console.log("CompressImage Error:", ex);
        });
    }
  }

  const handleFileUpload = ({path, file, fileName}) => {
    // console.log("path:", path, " file:", file);
    fileName = fileName.replace(/[()]/g, "");
    const storageRef = ref(storage, `${path}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log("Upload is " + progress + "% done");
        setProgress(prog);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        setIsUploading(false);
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized": // User doesn't have permission to access the object
            console.log("Upload Error: unauthorized access");
            alert("Upload Error: unauthorized access");
            break;
          case "storage/canceled": // User canceled the upload
            console.log("Upload Error: Upload is canceled");
            alert("Upload Error: Upload is canceled");
            break;
          case "storage/unknown": // Unknown error occurred, inspect error.serverResponse
            console.log("Upload Error:Unknown error occurred");
            alert("Upload Error:Unknown error occurred");
            break;
          default:
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            setIsUploading(false);

            console.log("File available at", downloadURL);
            categoryContext.setState({
              ...categoryContext.state,
              images: [
                ...categoryContext.state.images,
                {name: fileName, url: downloadURL},
              ],
            });
          })
          .catch((ex) => {
            setIsUploading(false);
            alert("Error while getting image url");
          });
      }
    );
  };
  const handleDeleteFile = (index, fileName) => {
    console.log("delete file:", index, " name:", fileName);
    const tempImageData = [...imageData];
    tempImageData.splice(index, 1);
    setImageData(tempImageData);
    const fileRef = ref(storage, `images/categories/${fileName}`);
    // Delete the file
    deleteObject(fileRef)
      .then(() => {
        // File deleted successfully
        console.log("deleted successfully");
        const updatedURLs = categoryContext.state.images.filter(
          (state) => state.name !== fileName
        );
        categoryContext.setState({
          ...categoryContext.state,
          images: updatedURLs,
        });
      })
      .catch((err) => {
        // Uh-oh, an error occurred!
        console.log("delete err:", err.message);
      });
  };
  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <FormControl fullWidth>
          <InputLabel id="parent-category">Parent (If any)</InputLabel>
          <Select
            labelId="parent-category"
            id="demo-simple-select"
            value={categoryContext.state.parentCategory.id}
            label="Parent (If any)"
            onChange={handleChange}
          >
            <MenuItem value="None">None</MenuItem>
            {allCategoriesArr.map((data, index) => {
              return (
                <MenuItem key={index} value={data.id}>
                  {data.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 xs={12} mb={2}>
        <TextField
          required
          fullWidth
          id="Name"
          label="Name"
          variant="standard"
          value={categoryContext.state.name}
          onChange={(e) => {
            categoryContext.setState({
              ...categoryContext.state,
              name: e.target.value,
              id: e.target.value.replace(/ /g, "_").toUpperCase(),
            });
          }}
          inputProps={titleInputLimit}
          disabled={action === "EDIT" ? true : false}
        />
      </Grid2>
      <Grid2 xs={12}>
        {imageData.map((img, index) => {
          const bgImg =
            img.url !== undefined
              ? `url(${img.url})`
              : `url(${img.prefix}${img.data})`;
          // console.log("img.url:", img.url, "__ bg img:", bgImg);
          return (
            <label className="uploaded-product" key={index}>
              <Paper
                style={{
                  backgroundImage: bgImg,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Tooltip title="Delete">
                  <IconButton
                    className="action-button"
                    aria-label="delete"
                    onClick={() => {
                      if (window.confirm("Sure to delete?"))
                        handleDeleteFile(index, img.alt || img.name);
                    }}
                  >
                    <DeleteIcon className="action-icons delete-icon" />
                  </IconButton>
                </Tooltip>
              </Paper>
            </label>
          );
        })}
        <FileUpload
          handleChange={handleImageChange}
          // bgImageSrc="https://via.placeholder.com/150"
          bgImageSrc={AddProductImage}
          upProgress={progress}
        />
      </Grid2>
      <CustomButton
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          handleSetCategory(action);
        }}
        fullWidth
        disabled={isUploading}
      >
        {action === "ADD" ? "Add Category" : "Update Category"}
      </CustomButton>
      {action === "EDIT" && (
        <CustomButton
          variant="outlined"
          color="primary"
          size="small"
          onClick={hideModal}
          fullWidth
          sx={{marginTop: "15px"}}
        >
          Cancel
        </CustomButton>
      )}
    </Grid2>
  );
};

export default AddCategory;
