import React, {useContext, useEffect, useState} from "react";
import SignUpForm from "../../../components/auth-forms/signup/SignUpForm";
import {onAuthStateChanged} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import UserProfileContext from "../../../context/userProfile/UserProfileContext";
import {auth} from "../../../firebase";
import AuthAPI from "../../../api/firebase/AuthAPI";
import Loader from "../../../components/loader/Loader";

const SignUpPage = ({redirect}) => {
  // Redirect is used for login in Placeorder component
  const userProfileContext = useContext(UserProfileContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const authAPI = new AuthAPI();
  // useEffect(() => {
  //   if (redirect) {
  //     const subscribe = onAuthStateChanged(auth, (user) => {
  //       if (user) {
  //         // User is signed in, see docs for a list of available properties
  //         // https://firebase.google.com/docs/reference/js/firebase.User
  //         const userId = user.id || user.uid;

  //         console.log("User signed in:", user, " userId:", userId);
  //         if (user.displayName === null) {
  //           console.log("In SignUp =====> Fetch detail by user id:", userId);
  //           authAPI.getUserDetailById(userId).then((res) => {
  //             console.log("In SignUp =====> RES:", res);
  //             userProfileContext.setState({
  //               ...res,
  //               id: userId,
  //             });
  //           });
  //         } else {
  //           userProfileContext.setState({
  //             ...user,
  //             cartItems: [],
  //             id: userId,
  //           });
  //         }
  //         navigate("/");
  //       } else {
  //         // User is signed out
  //         console.log("User signed out");
  //         userProfileContext.setState(null);
  //         setLoading(false);
  //       }
  //     });
  //     return subscribe;
  //   } else {
  //     setLoading(false);
  //   }
  // }, []);
  return (
    <>
      {!loading && (
        <div>
          <SignUpForm></SignUpForm>
        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default SignUpPage;
