import React, {useContext, useEffect, useState} from "react";
import HorizontalLinearStepper from "../../common/Stepper/HorizontalLinearStepper";
import useMediaQuery from "@mui/material/useMediaQuery";
import VerticalLinearStepper from "../../common/Stepper/VerticalLinearStepper";
import useTheme from "@mui/material/styles/useTheme";
import LoginStep from "./LoginStep";
import SelectAddressStep from "./SelectAddressStep";
import OrderSummary from "./OrderSummary";
import UserProfileContext from "../../../context/userProfile/UserProfileContext";
import {Button, Grid} from "@mui/material";
import CheckoutButton from "../../common/CheckoutButton/CheckoutButton";
import CustomButton from "../../common/Button/CustomButton";
import UserDataAPI from "../../../api/firebase/UserDataAPI";
import OrdersAPI from "../../../api/firebase/OrdersAPI";
import {useNavigate} from "react-router-dom";
const PlaceOrder = ({handleClose}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [user, setUser] = useState(null);
  const userProfileContext = useContext(UserProfileContext);
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [activeStep, setActiveStep] = useState(0);
  const [loginCompleted, setLoginCompleted] = useState(false);
  const [addressCompleted, setAddressCompleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cartItems, setCartItems] = useState([]);
  const [selAddress, setSelAddress] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(null);
  const userDataApi = new UserDataAPI();
  const ordersAPI = new OrdersAPI();
  useEffect(() => {
    setLoading(false);
    if (userProfileContext.state !== null) {
      console.log("user:", userProfileContext.state);
      setUser({...userProfileContext.state});
      setLoginCompleted(true);
    } else {
      setUser(null);
      setLoginCompleted(false);
    }
    if (
      userProfileContext.state !== null &&
      userProfileContext.state.cartItems !== undefined &&
      userProfileContext.state.cartItems !== null
    ) {
      setCartItems(userProfileContext.state.cartItems);
    } else {
      setCartItems([]);
    }
  }, [userProfileContext.state]);
  // useEffect(() => {
  //   console.log("activeStep in effect:", activeStep);
  // }, [activeStep]);

  // useEffect(() => {
  //   console.log("sel address:", selAddress);
  // }, [selAddress]);

  const handleStep = (index) => {
    console.log("handleStep");
    setActiveStep(index);
  };
  const handleCreateOrder = (props) => {
    console.log("handleCreateOrder:", props);
    const {success, order} = props;
    if (!success) return;
    const orderData = {
      id: order.id,
      amount: order.amount / 100,
      status: order.status,
      createdAt: new Date(order.created_at * 1000).toString(),
    };
    setCurrentOrder(orderData);
    // const userPrevOrders = user.orders !== undefined ? user.orders : [];
    // const data = {
    //   payload: {
    //     orders: [orderData, ...userPrevOrders],
    //   },
    //   id: user.uid,
    // };
    // console.log("data to send:", data);
    // userDataApi.addUserData(data).then((res) => {
    //   console.log("RES:", res);
    //   if (res.success) {
    //     const dataForOrderTable = {
    //       payload: {
    //         ...orderData,
    //         user: {
    //           id: user.uid,
    //           name: user.displayName,
    //           email: user.email,
    //         },
    //         items: [...cartItems],
    //         address: selAddress,
    //       },
    //       id: order.id,
    //     };
    //     ordersAPI.setOrder(dataForOrderTable).then((resOrder) => {
    //       console.log("RES ORDER:", resOrder);
    //       userProfileContext.setState({
    //         ...user,
    //         ...data.payload,
    //       });
    //     });
    //   }
    // });
  };
  const handlePaymentUpdate = (props) => {
    console.log(
      "handlePaymentUpdate props:",
      props,
      "__currentOrder:",
      currentOrder,
      "__user:",
      user
    );

    const {success, response} = props;
    if (success) {
      // Payment Completed
      const payment_id = response.razorpay_payment_id;
      const dataForOrderTable = {
        payload: {
          ...currentOrder,
          user: {
            id: user.id,
            name: user.displayName,
            email: user.email,
          },
          items: [...cartItems],
          address: selAddress,
          payment: {
            status: "completed",
            payment_id: payment_id,
            attempted_at: Date().toString(),
          },
          status: "placed",
        },
        id: currentOrder.id,
      };
      ordersAPI.setOrder(dataForOrderTable).then((resOrder) => {
        console.log("RES ORDER:", resOrder);
        const prevOrders = user.orders !== undefined ? user.orders : [];
        const dataForUsersTable = {
          id: user.id,
          payload: {
            orders: [{...currentOrder, status: "placed"}, ...prevOrders],
          },
        };
        console.log("dataForUsersTable:", dataForUsersTable);
        userDataApi.addUserData(dataForUsersTable).then((res) => {
          console.log("RES from user data update:", res);
          if (res.success) {
            userProfileContext.setState((prev) => ({
              ...prev,
              cartItems: [],
              orders: [...dataForUsersTable.payload.orders],
            }));
            // navigate to orders page
            navigate("/userOrders");
          }
        });
      });
    } else {
      // Payment failed
    }
  };
  const steps = [
    {
      label: "Customer Info",
      component: <LoginStep user={user} loading={loading} />,
      isCompleted: loginCompleted,
      subtitle:
        user !== null
          ? `${user.displayName} (${user.email})`
          : "Please Login first",
    },
    {
      label: "Delivery Address",
      component: (
        <SelectAddressStep
          setCompleted={setAddressCompleted}
          selAddress={selAddress}
          setSelAddress={setSelAddress}
        />
      ),
      isCompleted: addressCompleted,
      subtitle:
        selAddress !== null ? selAddress.address : "Where we should deliver ?",
    },
    {
      label: "Order Summary",
      component: <OrderSummary cartItems={cartItems} />,
    },
  ];

  return (
    <div style={{height: "100%"}}>
      {!mobileScreen && (
        <HorizontalLinearStepper
          steps={steps}
          activeStep={activeStep}
          handleStep={handleStep}
          handleClose={handleClose}
        />
      )}
      {mobileScreen && (
        <VerticalLinearStepper
          steps={steps}
          activeStep={activeStep}
          handleStep={handleStep}
          handleClose={handleClose}
        />
      )}
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={6} sm={3}>
          {activeStep === steps.length - 1 && (
            <CustomButton
              fullWidth
              size="small"
              variant="outlined"
              onClick={handleClose}
            >
              Edit Cart
            </CustomButton>
          )}
        </Grid>
        <Grid item xs={6} sm={3}>
          {activeStep === steps.length - 1 && (
            <CheckoutButton
              user={userProfileContext.state}
              amount={cartItems.reduce(
                (a, v) => (a = a + v.listPrice * v.quantity),
                0
              )}
              onCreateOrder={handleCreateOrder}
              currentOrder={currentOrder}
              onPayment={handlePaymentUpdate}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default PlaceOrder;
