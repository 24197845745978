import React, {useState, useEffect} from "react";
// import useRazorpay from "react-razorpay";
import CustomButton from "../Button/CustomButton";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import Loader from "../../loader/Loader";

const CheckoutButton = ({
  user,
  amount,
  onCreateOrder,
  currentOrder,
  onPayment,
}) => {
  // const Razorpay = useRazorpay();
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(false);
  const createOrder = async (order) => {
    setLoading(true);
    const url = "https://payments.ivsoftsolutions.in/public/api/createOrder";
    try {
      await fetch(url, {
        method: "POST",
        body: JSON.stringify(order),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data:", data);
          setOrderData(data);
        });
    } catch (error) {
      setLoading(false);
      console.log("Server Error:", error);
    }
  };
  function handlePayment() {
    const options = {
      key: "rzp_test_ExsR0PlNri5lIM", // Enter the Key ID generated from the Dashboard
      amount: amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Plant Space", //your business name
      description: "Description for Transaction",
      image: "https://myecommerce-a647b.web.app/favicon.ico",
      order_id: orderData !== null ? orderData.id : "", //This is the `id` obtained in the response of Step 1
      handler: (response) => {
        console.log("Response:", response);
        onPayment({success: true, response});
        setOrderData(null);
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: user.displayName, //your customer's name
        email: user.email,
        contact: user.mobile, //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: process.env.REACT_APP_BG_COLOR,
      },
    };
    //const rzp1 = new Razorpay(options);

    // rzp1.on("payment.failed", function (response) {
    //   console.log("payment.failed");
    //   setLoading(false);
    //   onPayment({success: false, response});
    //   alert(response.error.code);
    //   alert(response.error.description);
    //   alert(response.error.source);
    //   alert(response.error.step);
    //   alert(response.error.reason);
    //   alert(response.error.metadata.order_id);
    //   alert(response.error.metadata.payment_id);
    // });
    // rzp1.open();
  }
  useEffect(() => {
    if (orderData !== null && orderData !== undefined) {
      setLoading(false);
      onCreateOrder({success: true, order: orderData});
    }
  }, [orderData]);
  useEffect(() => {
    if (currentOrder !== null && currentOrder !== undefined) {
      handlePayment();
    }
  }, [currentOrder]);

  return (
    <>
      <CustomButton
        variant="contained"
        fullWidth
        startIcon={<ShoppingCartCheckoutIcon />}
        size="small"
        onClick={(e) => {
          createOrder({
            amount: amount * 100,
            receipt: "receipt_3",
            notes: {key1: "val1", key2: "val2"},
          });
        }}
      >
        Checkout
      </CustomButton>
      {loading && <Loader />}
    </>
  );
};

export default CheckoutButton;
