import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
const VerticalLinearStepper = ({
  steps = [],
  activeStep = 0,
  handleStep = () => {},
  handleClose = () => {},
  editable = true,
}) => {
  return (
    <Box>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{marginBottom: activeStep === 2 && editable ? "1rem" : 0}}
      >
        {steps.map((step, index) => {
          return (
            <Step key={step.label}>
              <StepLabel
                optional={
                  <Typography
                    variant="caption"
                    noWrap
                    sx={{
                      maxWidth: "80% !important",
                      display: "block",
                      fontWeight: activeStep >= index ? 500 : 300,
                    }}
                  >
                    {step.subtitle}
                  </Typography>
                }
              >
                {step.label}
              </StepLabel>
              {editable && (
                <StepContent
                  sx={{
                    maxHeight: activeStep === 2 ? "44vh" : "auto",
                    overflowY: "overlay",
                  }}
                >
                  {step.component}
                  <Box sx={{mb: 2}}>
                    <div>
                      {index > 0 && (
                        <Button
                          disabled={index === 0}
                          onClick={() => handleStep(activeStep - 1)}
                          sx={{mt: 1, mr: 1}}
                        >
                          Back
                        </Button>
                      )}
                      {step.isCompleted && activeStep !== steps.length - 1 && (
                        <Button
                          variant="contained"
                          onClick={() => handleStep(activeStep + 1)}
                          sx={{mt: 1, mr: 1}}
                        >
                          Proceed
                        </Button>
                      )}
                      {!step.isCompleted && activeStep !== steps.length - 1 && (
                        <Button
                          variant="contained"
                          disabled
                          sx={{mt: 1, mr: 1}}
                        >
                          Proceed
                        </Button>
                      )}
                    </div>
                  </Box>
                </StepContent>
              )}
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
export default VerticalLinearStepper;
