import {onAuthStateChanged} from "firebase/auth";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AuthAPI from "../../../api/firebase/AuthAPI";
import LoginForm from "../../../components/auth-forms/login/LoginForm";
import UserProfileContext from "../../../context/userProfile/UserProfileContext";
import {auth} from "../../../firebase";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LikedItemsContext from "../../../context/likedItems/LikedItemsContext";
import CartItemsContext from "../../../context/cartItems/CartItemsContext";
import Loader from "../../../components/loader/Loader";
import {Container} from "@mui/material";

const LoginPage = ({handleCloseListener = () => {}, redirect = true}) => {
  // Redirect is used for login in Placeorder component
  const userProfileContext = useContext(UserProfileContext);
  const likedItemsContext = useContext(LikedItemsContext);
  const cartItemsContext = useContext(CartItemsContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const authAPI = new AuthAPI();

  useEffect(() => {
    if (!redirect) {
      setLoading(false);
    }
    const subscribe = onAuthStateChanged(auth, (user) => {
      console.log("Logged In User :", user);
      const currentLikedItems =
        likedItemsContext.state !== null ? likedItemsContext.state : [];
      const currentCartItems =
        cartItemsContext.state !== null ? cartItemsContext.state : [];

      let finalUserData = {};
      if (user) {
        const userId = user.uid || user.id;
        console.log(
          "LOGIN PAGE ====> User signed in:",
          user,
          " userId:",
          userId
        );

        if (user.providerId !== undefined && user.providerId === "firebase") {
          finalUserData = {
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL || "",
            id: userId,
          };
        }

        console.log("In Login ===> Fetch detail by user id:", userId);

        authAPI.getUserDetailById(userId).then((res) => {
          console.log(
            "In Login ===> getUserDetailById RES:",
            res,
            "____redirect:",
            redirect
          );
          setLoading(false);
          if (!res) {
            finalUserData = {
              ...finalUserData,
              role: ["User"],
              likedItems: currentLikedItems,
              cartItems: currentCartItems,
            };
            const userDetail = {
              id: userId,
              payload: {...finalUserData},
            };
            authAPI.setUserInTable(userDetail);
          } else {
            finalUserData = {...finalUserData, ...res};
            if (res.cartItems !== undefined) {
              const userPrevCartItems = res.cartItems.filter(function (item) {
                for (var i = 0, len = currentCartItems.length; i < len; i++) {
                  if (
                    currentCartItems[i] !== undefined &&
                    currentCartItems[i].id === item.id
                  ) {
                    return false;
                  }
                }
                return true;
              });
              const combinedCartItems = [
                ...currentCartItems,
                ...userPrevCartItems,
              ];
              finalUserData = {
                ...finalUserData,
                cartItems: [...combinedCartItems],
              };
            } else {
              finalUserData = {
                ...finalUserData,
                cartItems: [...currentCartItems],
              };
            }
            if (res.likedItems !== undefined) {
              const userPrevLikedItems = res.likedItems.filter(function (item) {
                for (var i = 0, len = currentCartItems.length; i < len; i++) {
                  if (
                    currentLikedItems[i] !== undefined &&
                    currentLikedItems[i].id === item.id
                  ) {
                    return false;
                  }
                }
                return true;
              });
              const combinedLikedItems = [
                ...currentLikedItems,
                ...userPrevLikedItems,
              ];
              finalUserData = {
                ...finalUserData,
                likedItems: [...combinedLikedItems],
              };
            } else {
              finalUserData = {
                ...finalUserData,
                likedItems: [...currentLikedItems],
              };
            }
          }
          console.log("finalUserData:", finalUserData);
          userProfileContext.setState({...finalUserData});
        });

        if (redirect) {
          navigate("/");
          handleCloseListener();
        }
      } else {
        console.log("User signed out");
        userProfileContext.setState(null);
        setLoading(false);
      }
    });
    return subscribe;
  }, []);

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{p: redirect ? "" : 0, pt: "2rem"}}
    >
      {loading && <Loader />}
      {!loading && <LoginForm redirect={redirect}></LoginForm>}
    </Container>
  );
};

export default LoginPage;
