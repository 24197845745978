import {Box, Divider} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import CategoriesContainer from "../../../components/app/Categories/CategoriesContainer";
import ImageCarousel from "../../../components/common/ImageCarousel";
import ProductsContainer from "../../../components/app/Products/ProductsContainer";
import ProductsAPI from "../../../api/firebase/ProductsAPI";

import slide1 from "./../../../assets/images/slider/slide-3.jpg";
import slide2 from "./../../../assets/images/slider/slide-2.jpg";
import slide3 from "./../../../assets/images/slider/slide-1.jpg";
import {useLoaderData} from "react-router-dom";
import AllProductContext from "../../../context/allProducts/AllProductsContext";
import AllCategoriesContext from "../../../context/allCategories/AllCategoriesContext";
import ReviewsAPI from "../../../api/firebase/ReviewsAPI";
import AllReviewsContext from "../../../context/allReviews/AllReviewsContext";

const Homepage = () => {
  const sliderImagesArr = [slide1, slide2, slide3];
  const allProductsContext = useContext(AllProductContext);
  const allReviewsContext = useContext(AllReviewsContext);

  const loaderData = useLoaderData();
  const productsArray = loaderData.data;
  const reviewsAPI = new ReviewsAPI();

  //console.log("allReviewsContext:", allReviewsContext.state);

  const allCategoriesContext = useContext(AllCategoriesContext);
  const [allCategoriesArr, setAllCategoriesArr] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  useEffect(() => {
    allProductsContext.setState(productsArray);
    //console.log("Products Array:", productsArray);
  }, []);
  useEffect(() => {
    reviewsAPI.getReviews().then((res) => {
      //console.log("getReviews RES:", res);
      if (res.success) {
        allReviewsContext.setState(res.data);
      }
    });
  }, [allProductsContext.state]);

  // Fetch all categories
  useEffect(() => {
    setAllCategoriesArr(allCategoriesContext.state);
  }, [allCategoriesContext.state]);
  useEffect(() => {
    // console.log("allCategoriesArr:", allCategoriesArr);
    const parentCats = allCategoriesArr.filter((item) => {
      return item.parentCategory.id === "None";
    });
    setParentCategories(parentCats);
    // console.log("parentCats:", parentCats);
  }, [allCategoriesArr]);

  return (
    <>
      <Box component="main" sx={{width: "100%"}}>
        <Box className="">
          <ImageCarousel ImagesArr={sliderImagesArr} />
        </Box>
        <CategoriesContainer
          heading="What are you looking for ?"
          categories={parentCategories}
          view="RECTANGLE"
        />

        {productsArray.length > 0 && (
          <>
            <Divider />
            <ProductsContainer
              heading="Best Rated Plants"
              productsArray={productsArray}
              showCarousel={false}
            />
          </>
        )}
      </Box>
    </>
  );
};
export const DataLoader = async () => {
  const productAPI = new ProductsAPI();
  const resProducts = productAPI.getProducts();
  return resProducts;
};
export default Homepage;
